import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
// @mui
import {
  Paper,
  Stack,
  Button,
  List,
  OutlinedInput,
  IconButton,
  Typography,
  Popover,
  MenuItem,
} from '@mui/material';
// redux
// import { deleteColumn, updateColumn, addTask, deleteTask } from '../../../redux/slices/kanban';
// components
import Iconify from '../../../components/Iconify';
//
import KanbanAddTask from './KanbanTaskAdd';
import SlideCard from './SlideCard';
import KanbanColumnToolBar from './KanbanColumnToolBar';
import Scrollbar from '../../../components/Scrollbar';
import {
  addSlide,
  setCurrentPresentation,
  setIsSaving,
  setSlidesOrder,
} from '../../../store/presentationSlice';

// ----------------------------------------------------------------------

SlideColumn.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
};

export default function SlideColumn({ slides: data }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  // const [cards, setCards] = useState(slides);

  const { currentPresentation } = useSelector((state) => state.presentations);

  const [slides, setSlides] = useState(data);

  const deleteColumn = () => {};
  const updateColumn = () => {};
  const addTask = () => {};
  const deleteTask = () => {};

  useEffect(() => {
    setSlides(data);
  }, [data]);

  const addNewSlide = async ({ slideType }) => {
    dispatch(setIsSaving(true));
    setOpen(false);
    const { payload } = await dispatch(
      addSlide({ id: currentPresentation.id, slideType }),
    );
    dispatch(setIsSaving(false));
  };

  const updateSlideOrder = async ({ slides }) => {
    dispatch(setCurrentPresentation({ ...currentPresentation, slides }));
    dispatch(setIsSaving(true));

    await dispatch(setSlidesOrder({ id: currentPresentation.id, slides }));
    dispatch(setIsSaving(false));
  };

  const handleNewSlideMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    const sourceIdx = +source.index;
    const destIdx = +destination.index;
    const newOrder = Object.values(slides);
    const [move] = newOrder.splice(sourceIdx, 1);
    newOrder.splice(destIdx, 0, move);

    updateSlideOrder({ slides: newOrder });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Paper
        variant="outlined"
        sx={{ px: 2, border: '0px', height: '100%', width: '100%' }}
      >
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            sx: {
              p: 1,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            sx={{ color: 'primary.main' }}
            onClick={() => {
              addNewSlide({ slideType: 'QUIZ' });
            }}
          >
            <Iconify icon={'ri:bar-chart-2-fill'} sx={{ mr: 2 }} />
            Multiple Choice
          </MenuItem>
          <MenuItem
            sx={{ color: 'secondary.main' }}
            onClick={() => {
              addNewSlide({ slideType: 'HEADING' });
            }}
          >
            <Iconify icon={'fluent:slide-layout-20-filled'} sx={{ mr: 2 }} />
            Heading
          </MenuItem>

          <MenuItem
            sx={{ color: 'tertiary.main' }}
            onClick={() => {
              addNewSlide({ slideType: 'PARAGRAPH' });
            }}
          >
            <Iconify icon={'fluent:slide-text-16-filled'} sx={{ mr: 2 }} />
            Paragraph
          </MenuItem>
        </Popover>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{ pt: 3, mb: 3 }}
        >
          <Typography color="text.disabled">Presentation Slides</Typography>
          <IconButton onClick={handleNewSlideMenu}>
            <Iconify icon="eva:plus-fill" />
          </IconButton>
        </Stack>

        <Droppable droppableId={'minhducdeptrai'}>
          {(provided) => (
            <Scrollbar>
              <Stack
                ref={provided.innerRef}
                {...provided.droppableProps}
                direction="column"
                spacing={2}
                height="100%"
              >
                {currentPresentation?.slides?.map((slide, index) => (
                  <SlideCard key={slide.id} slide={slide} index={index} />
                ))}
                {provided.placeholder}
              </Stack>
            </Scrollbar>
          )}
        </Droppable>
      </Paper>
    </DragDropContext>
  );
}
