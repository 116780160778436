import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Backdrop,
  Button,
  Card,
  IconButton,
  Portal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../components/Iconify';
import useResponsive from '../../../hooks/useResponsive';
import {
  markQuestionAsAnswered,
  sendQuestion,
  upvoteQuestion,
} from '../../../store/presentationSessionSlice';
// components

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  // right: 0,
  // bottom: 0,
  zIndex: 1999,
  // minHeight: 440,
  left: '50%',
  top: '50%',
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.paper,
  transform: `translate(-50%, -50%)`,
}));

// ----------------------------------------------------------------------

QuestionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default function QuestionModal({ isOpen, onClose, onSubmit, isViewer }) {
  const dispatch = useDispatch();

  const [fullScreen, setFullScreen] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [askQuestion, setAskQuestion] = useState('');

  const { questions, session } = useSelector(
    (state) => state.presentationSession,
  );
  const [currIdx, setCurrIdx] = useState(
    questions?.length ? questions.length - 1 : 0,
  );
  const currQuestion = questions[currIdx];

  const isDesktop = useResponsive('up', 'sm');

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  const submitQuestion = async () => {
    await dispatch(
      sendQuestion({ id: session.id, question: { title: askQuestion } }),
    );
    setAskQuestion('');
    setIsEditMode(false);
  };

  const backQuestion = () => {
    if (currIdx === 0) return;
    setCurrIdx(currIdx - 1);
  };

  const nextQuestion = () => {
    if (currIdx === questions?.length - 1) return;
    setCurrIdx(currIdx + 1);
  };

  const markAsAnswered = async () => {
    await dispatch(
      markQuestionAsAnswered({ id: session.id, question: currQuestion }),
    );
  };

  const vote = async () => {
    await dispatch(upvoteQuestion({ id: session.id, question: currQuestion }));
  };
  return (
    <Portal>
      <Backdrop open={fullScreen || !isDesktop} sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          ...(fullScreen && {
            justifyContent: 'center',
            zIndex: 1999,
          }),
        }}
      >
        <Card sx={{ height: '', padding: '15px', display: 'flex' }}>
          <Stack direction="column" sx={{ width: '100vh' }}>
            <IconButton sx={{ width: 60, height: 60 }} onClick={handleClose}>
              <Iconify icon={'eva:close-fill'} />
            </IconButton>

            {!isEditMode && questions && currQuestion && (
              <>
                <Stack
                  sx={{ flex: 0.2 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    sx={{ width: 60, height: 60 }}
                    onClick={backQuestion}
                  >
                    <Iconify icon="material-symbols:keyboard-arrow-up" />
                  </IconButton>
                </Stack>
                <Stack
                  direction="column"
                  sx={{ flex: 0.6 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h4" component="h2">
                    {currIdx + 1} / {questions.length}
                  </Typography>
                  <Typography variant="h4">
                    Asked on Slide with heading
                  </Typography>
                  <Typography variant="h2">{currQuestion?.title}</Typography>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="h3">
                      {currQuestion?.votes?.length || 0}
                    </Typography>
                    <IconButton sx={{ width: 60, height: 60 }} onClick={vote}>
                      <Iconify icon="fluent:thumb-like-20-regular" />
                    </IconButton>
                  </Stack>
                </Stack>

                <Stack
                  justifyContent="center"
                  alignItems="center"
                  onClick={nextQuestion}
                >
                  {isViewer && currQuestion?.isAnswered && (
                    <Button
                      variant="contained"
                      sx={{ width: '250px' }}
                      startIcon={
                        <Iconify
                          icon={'eva:checkmark-fill'}
                          width={16}
                          height={16}
                        />
                      }
                      disabled
                    >
                      Answered
                    </Button>
                  )}
                  <IconButton sx={{ width: 60, height: 60 }}>
                    <Iconify icon="material-symbols:keyboard-arrow-down" />
                  </IconButton>
                </Stack>
              </>
            )}

            <Stack
              sx={{ flex: 0.2, m: 2 }}
              justifyContent="center"
              alignItems="center"
            >
              {!isEditMode && !questions.length && 'No Questions'}
              {isViewer && isEditMode && (
                <>
                  <Typography variant="h5">
                    Write your question here...
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={askQuestion}
                    onChange={(e) => setAskQuestion(e.target.value)}
                  />
                  <Stack sx={{ m: 2, width: '100%' }}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={submitQuestion}
                    >
                      Submit
                    </Button>
                  </Stack>
                </>
              )}

              {isViewer && !isEditMode && (
                <Stack sx={{ m: 2, width: '100%' }}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<Iconify icon={'ic:twotone-mark-chat-unread'} />}
                    onClick={() => setIsEditMode(true)}
                  >
                    Ask a question
                  </Button>
                </Stack>
              )}
              {!isViewer && currQuestion && (
                <Button
                  variant="contained"
                  sx={{ width: '250px' }}
                  startIcon={
                    currQuestion?.isAnswered && (
                      <Iconify
                        icon={'eva:checkmark-fill'}
                        width={16}
                        height={16}
                      />
                    )
                  }
                  disabled={currQuestion.isAnswered}
                  onClick={markAsAnswered}
                >
                  {currQuestion?.isAnswered ? 'Answered' : 'Mark as answered'}
                </Button>
              )}
            </Stack>
          </Stack>
        </Card>
      </RootStyle>
    </Portal>
  );
}
