import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  Alert,
  Button,
  IconButton,
} from '@mui/material';
import { fData } from '../../utils/formatNumber';
import { PATH_DASHBOARD, PATH_GROUP } from '../../routes/paths';
import Label from '../../components/Label';
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFUploadCover,
} from '../../components/hook-form';
import { createGroup, deleteGroup, updateGroup } from '../../store/groupSlice';
import { appDispatch } from '../../store/appDispatch';
import { getUserInfo } from '../../store/userSlice';
import DeleteGroupModal from './modal/DeleteModal';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

GroupForm.propTypes = {
  isEdit: PropTypes.bool,
  currentGroup: PropTypes.object,
};

export default function GroupForm({ isEdit, currentGroup }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [isOpen, setIsOpen] = useState(null);

  const onClose = () => {
    setIsOpen(null);
  };

  const onDelete = async () => {
    onClose();
    const { error } = await appDispatch(
      dispatch,
      deleteGroup({ id: currentGroup.id }),
    );
    if (!error) navigate(PATH_GROUP.root);
  };

  const Schema = Yup.object().shape({
    owner: Yup.string()
      .required('Owner is required')
      .email('Owner must be a valid email'),
    coOwner: Yup.string().email('Co-Owner must be a valid email'),
    name: Yup.string().required('Group Name is required'),
    description: Yup.string().required('Description is required'),
  });

  const defaultValues = useMemo(
    () => ({
      owner: currentGroup?.owner?.email || user?.email || null,
      coOwner: currentGroup?.coOwner?.email || '',
      name: currentGroup?.name || '',
      description: currentGroup?.description || '',
      avatar: currentGroup?.avatar || '',
      cover: currentGroup?.cover || '',
      isPublic: currentGroup?.isPublic || false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentGroup, user],
  );

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentGroup) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentGroup]);

  const onSubmit = async (data) => {
    if (isEdit) {
      await dispatch(updateGroup({ ...data, id: currentGroup.id }));
    } else {
      const { error, payload } = await dispatch(createGroup(data));
      if (!error) navigate(PATH_GROUP.feed(payload.id));
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatar',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
      }
    },
    [setValue],
  );

  const handleDropCover = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'cover',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
      }
    },
    [setValue],
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DeleteGroupModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => onDelete()}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <Card sx={{ p: 3 }}>
            <RHFUploadCover
              name="cover"
              accept="image/*"
              maxSize={10000000}
              onDrop={handleDropCover}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(10000000)}
                </Typography>
              }
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 7, px: 3 }}>
            {isEdit && (
              <Label
                color={values.status !== 'active' ? 'error' : 'success'}
                sx={{
                  textTransform: 'uppercase',
                  position: 'absolute',
                  top: 24,
                  right: 24,
                }}
              >
                {values.status}
              </Label>
            )}

            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatar"
                accept="image/*"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>

            <RHFSwitch
              name="isPublic"
              labelPlacement="start"
              label={
                <>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Public Group
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Disabling this will restrict access
                  </Typography>
                </>
              }
              sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                },
              }}
            >
              <RHFTextField name="owner" label="Owner" disabled />
              <RHFTextField name="coOwner" label="Co-Owner" />
              <RHFTextField name="name" label="Group Name" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField
                name="description"
                multiline
                rows={4}
                label="Description"
              />
              <Stack spacing={3} alignItems="flex-end" direction="row">
                {isEdit ? (
                  <IconButton color="error" onClick={() => setIsOpen(true)}>
                    <Iconify icon={'eva:trash-2-outline'} />
                  </IconButton>
                ) : null}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {!isEdit ? 'Create Group' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
