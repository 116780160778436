import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// @mui
import { Box, Divider, Stack } from '@mui/material';
// redux
import { useDispatch, useSelector } from 'react-redux';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
//

import ChatMessageList from './ChatMessageList';
import ChatMessageInput from './ChatMessageInput';
import {
  fetchChat,
  sendMessage,
  setSession,
} from '../../../store/presentationSessionSlice';

// ----------------------------------------------------------------------
export default function ChatWindow() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { chat, session, chatNextPage } = useSelector(
    (state) => state.presentationSession,
  );

  const handleSendMessage = async ({ message }) => {
    dispatch(sendMessage({ id: session.id, message: { message } }));
  };

  const loadMore = async () => {
    dispatch(fetchChat({ id: session.id, page: chatNextPage }));
  };

  return (
    <Stack sx={{ flexGrow: 1, minWidth: '1px' }}>
      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Stack sx={{ flexGrow: 1 }}>
          <ChatMessageList messages={chat} onLoadMore={loadMore} />
          <Divider />
          <ChatMessageInput
            conversationId={session.id}
            onSend={handleSendMessage}
          />
        </Stack>
      </Box>
    </Stack>
  );
}
