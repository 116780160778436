import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PATH_DASHBOARD, PATH_GROUP, PATH_PAGE } from '../../routes/paths';
import { appDispatch } from '../../store/appDispatch';
import { joinByLink } from '../../store/groupSlice';

export const GroupJoin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const invitedCode = searchParams.get('invitedCode');
  const invitationId = searchParams.get('invitationId');
  const { id } = useParams();
  const dispatch = useDispatch();
  const join = async () => {
    const { error } = await appDispatch(
      dispatch,
      joinByLink({ invitationId, invitedCode, groupId: id }),
    );
    if (error) return navigate(PATH_PAGE.page404);
    return navigate(PATH_GROUP.feed(id));
  };
  useEffect(() => {
    join();
  }, []);

  return <></>;
};
