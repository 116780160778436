import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_PRESENTATION } from '../../routes/paths';
import useSettings from '../../hooks/useSettings';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import PermissionDeniedContainer from '../../sections/PermissionDeniedContainer';
import PresentationCollaborators from '../../sections/presentation/collaborators/PresentationCollaborators';
import PresentationForm from '../../sections/presentation/PresentationForm';
import { retrievePresentation } from '../../store/presentationSlice';

export default function PresentationCreateOrEdit() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const { id } = useParams();
  const [presentation, setPresentation] = useState();
  const isEdit = pathname.includes('edit');
  const [currentTab, setCurrentTab] = useState('general');

  const isOwner = user?.id === presentation?.owner?.id;

  const fetchPresentation = async (id) => {
    const { payload } = await dispatch(retrievePresentation(id));
    setPresentation(payload);
  };

  const PRESENTATION_TABS = [
    {
      value: 'general',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component:
        !isEdit || isOwner ? (
          <PresentationForm
            isEdit={isEdit}
            currentPresentation={presentation}
          />
        ) : (
          <PermissionDeniedContainer />
        ),
    },
    {
      value: 'collaborators',
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      component: (
        <PresentationCollaborators
          presentation={presentation}
          isOwner={isOwner}
          refresh={() => fetchPresentation(id)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (id && isEdit) fetchPresentation(id);
  }, [id]);

  return (
    <Page title="Presentation">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create A New Presentation' : 'Edit Presentation'}
          links={[
            // { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Presentation', href: PATH_PRESENTATION.root },
            {
              name: !isEdit ? 'Create' : capitalCase(presentation?.name || ''),
            },
          ]}
        />

        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {PRESENTATION_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>
        {PRESENTATION_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
