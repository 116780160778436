import PropTypes from 'prop-types';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// @mui
import {
  Paper,
  Typography,
  Box,
  Checkbox,
  useTheme,
  IconButton,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
// components
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
//
import KanbanTaskDetails from './KanbanTaskDetails';
import { setCurrentSlide } from '../../../store/presentationSlice';
import Slide from '../../../pages/Presentation/Slide';

// ----------------------------------------------------------------------

SlideCard.propTypes = {
  slide: PropTypes.object,
  index: PropTypes.number,
};

export default function SlideCard({ slide, index }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [openDetails, setOpenDetails] = useState(false);

  const { currentSlide, currentPresentation } = useSelector(
    (state) => state.presentations,
  );

  const isSelected = currentSlide?.id === slide?.id;

  const handleOpenDetails = () => {
    dispatch(setCurrentSlide(slide));
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const getIcon = (slideType) => {
    switch (slideType) {
      case 'QUIZ':
        return <Iconify icon={'material-symbols:bar-chart'} />;
      case 'HEADING':
        return <Iconify icon={'fluent:slide-layout-20-filled'} />;
      default:
        return <Iconify icon={'fluent:slide-text-16-filled'} />;
    }
  };

  return (
    <Draggable draggableId={slide?.id?.toString()} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Paper
            sx={{
              px: 0,
              width: 1,
              position: 'relative',
              boxShadow: (theme) => theme.customShadows.z1,
              '&:hover': {
                boxShadow: (theme) => theme.customShadows.z16,
              },
              pt: 2,
            }}
          >
            <Typography
              fontSize={'13px'}
              color={isSelected ? theme.palette.primary.main : 'text.disabled'}
            >
              {index + 1}
            </Typography>
            <Box onClick={handleOpenDetails} sx={{ cursor: 'pointer' }}>
              <Box
                sx={{
                  pt: '60%',
                  borderRadius: 1,
                  ...(isSelected && {
                    border: 3,
                    borderColor: theme.palette.primary.main,
                  }),
                  overflow: 'hidden',
                  position: 'relative',
                  transition: (theme) =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest,
                    }),
                  ...(!isSelected && {
                    opacity: 0.48,
                  }),
                }}
              >
                <Image
                  src={slide.image || '/assets/slide_placeholder.jpg'}
                  sx={{ position: 'absolute', top: 0, width: 1, height: 1 }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    textAlign: 'center',
                    top: '50%',
                    left: '50%',
                    p: 1,
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <Typography variant="subtitle1" noWrap>
                    {slide?.header}
                  </Typography>
                  <IconButton>{getIcon(slide.slideType)}</IconButton>
                </Box>
              </Box>
            </Box>
          </Paper>

          {/* <KanbanTaskDetails
            card={slide}
            isOpen={openDetails}
            onClose={handleCloseDetails}
            // onDeleteTask={() => onDeleteTask(card.id)}
          /> */}
        </div>
      )}
    </Draggable>
  );
}
