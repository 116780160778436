import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Input,
  Portal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  // right: 0,
  // bottom: 0,
  zIndex: 1999,
  // minHeight: 440,
  left: '50%',
  top: '50%',
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.paper,
  transform: `translate(-50%, -50%)`,
}));

const InputStyle = styled(Input)(({ theme }) => ({
  padding: theme.spacing(0.5, 3),
  // borderBottom: `solid 1px ${theme.palette.divider}`,
}));

// ----------------------------------------------------------------------

InviteLinkModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default function InviteLinkModal({ link, isOpen, onClose, onSubmit }) {
  const [fullScreen, setFullScreen] = useState(true);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState();

  const copyLink = (link) => {
    if (link) copy(link);
  };

  const isDesktop = useResponsive('up', 'sm');

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <Backdrop open={fullScreen || !isDesktop} sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          ...(fullScreen && {
            justifyContent: 'center',
            zIndex: 1999,
            padding: '20px',
          }),
        }}
      >
        <Box
          sx={{
            pl: 3,
            pr: 1,
            height: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Share Link</Typography>
          <Box sx={{ flexGrow: 1 }} />

          <IconButton onClick={handleClose}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Box>
        <Stack direction="row" alignItems="center">
          <TextField
            fullwidth
            placeholder=""
            value={link}
            disabled
            // onChange={(event) => { setEmail(event.target.value) }}
            sx={{
              ml: 2,
              mr: 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) =>
                  `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />
          <IconButton onClick={() => copyLink(link)}>
            <Iconify
              icon={'material-symbols:content-copy-outline'}
              width={20}
              height={20}
            />
          </IconButton>
          {/* <Button size='large' variant='contained' onClick={() => onSubmit(email)}>Invite</Button> */}
        </Stack>
      </RootStyle>
    </Portal>
  );
}
