import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  Popover,
  Portal,
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// components
import Iconify from '../../../components/Iconify';
import InputStyle from '../../../components/InputStyle';
import { appDispatch } from '../../../store/appDispatch';
import {
  inviteMember,
  kickMember,
  updateGroup,
} from '../../../store/groupSlice';
import AddCollaboratorModal from './AddCollaboratorModal';
import {
  addCollaborator,
  removeCollaborator,
} from '../../../store/presentationSlice';
import PermissionDeniedContainer from '../../PermissionDeniedContainer';
import PermissionDeniedModal from '../../PermissionDeniedModal';
import { setShowPermissionDeniedModal } from '../../../store/modalDispatch';

// ----------------------------------------------------------------------

PresentationCollaborators.propTypes = {
  presentation: PropTypes.object,
  refresh: PropTypes.func,
};

export default function PresentationCollaborators({
  presentation,
  isOwner,
  refresh,
}) {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [isColabModalOpen, setIsColabModalOpen] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOpenMenu = (event, user) => {
    if (!isOwner) return;
    setIsMenuOpen(event.currentTarget);
    setSelectedUser(user);
  };

  const closeMenu = () => {
    setIsMenuOpen(null);
  };

  const closeColabModal = () => {
    setIsColabModalOpen(false);
  };

  const handleNewBtnClick = () => {
    if (isOwner) setIsColabModalOpen(true);
    else dispatch(setShowPermissionDeniedModal(true));
  };

  useEffect(() => {}, [query]);

  const remove = async (user) => {
    closeMenu();
    await appDispatch(
      dispatch,
      removeCollaborator({ id: presentation.id, userId: user.id }),
    );
    await refresh();
  };

  const add = async (email) => {
    closeColabModal();
    await appDispatch(
      dispatch,
      addCollaborator({ id: presentation.id, email }),
    );
    await refresh();
  };

  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Collaborators
        </Typography>
        <AddCollaboratorModal
          isOpen={isColabModalOpen}
          onClose={closeColabModal}
          onSubmit={add}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputStyle
            stretchStart={240}
            // value={query}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Find collaborators..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              // ml: 2,
              // mr: 1,
              // p: 3,
              mb: 3,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) =>
                  `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />

          <Button
            variant="contained"
            size="large"
            onClick={handleNewBtnClick}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Collaborators
          </Button>
        </Stack>

        <Grid container spacing={3}>
          {applyFilter(presentation?.collaborators, query)?.map(
            (collaborator) => (
              <Grid key={collaborator.id} item xs={12} md={4}>
                <CollaboratorCard
                  user={collaborator}
                  hasAccess={isOwner}
                  onOpenMenu={handleOpenMenu}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Box>
      <Popover
        open={Boolean(isMenuOpen)}
        anchorEl={isMenuOpen}
        onClose={() => setIsMenuOpen(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            remove(selectedUser);
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Kick
        </MenuItem>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

CollaboratorCard.propTypes = {
  user: PropTypes.object,
};

function CollaboratorCard({ user, hasAccess, onOpenMenu }) {
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Avatar
        alt={user?.email}
        src={user?.avatar}
        sx={{ width: 48, height: 48 }}
      />
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {`${user?.lastName} ${user?.firstName}`}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Iconify
            icon={'eva:pin-fill'}
            sx={{ width: 16, height: 16, mr: 0.5, flexShrink: 0 }}
          />
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>
      </Box>
      <Button
        size="small"
        onClick={(e) => hasAccess && onOpenMenu(e, user)}
        variant={'text'}
        color={'primary'}
      >
        Collaborator
      </Button>
    </Card>
  );
}

function applyFilter(array, query) {
  if (query) {
    return array?.filter(
      (member) =>
        `${member?.lastName} ${member.firstName}`
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1,
    );
  }

  return array;
}
