import PropTypes from 'prop-types';
// @mui
import { Card, Stack, Typography, Divider } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

GroupMemberPresentationInfo.propTypes = {
  group: PropTypes.shape({
    follower: PropTypes.number,
    following: PropTypes.number,
  }),
};

export default function GroupMemberPresentationInfo({ group }) {
  return (
    <Card sx={{ py: 3 }}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack width={1} textAlign="center">
          <Typography variant="h4">{fNumber(group?.memberCount)}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Members
          </Typography>
        </Stack>

        <Stack width={1} textAlign="center">
          <Typography variant="h4">{fNumber(group)}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Presentation
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
