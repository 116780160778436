import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { axiosAPI } from '../api/api';
import { PATH_GROUP } from '../routes/paths';
import {
  handleDeleteDispatch,
  handleGetDispatch,
  handlePostDispatch,
  handlePostDispatchWithAvatar,
  handlePutDispatchWithAvatar,
} from './handleDispatch';
import { setIsLoading } from './loadingSlice';
import { toastifyAction } from './toastifySlice';

const modulePrefix = 'group';

const createGroup = createAsyncThunk(
  'createGroup',
  async (postData, thunkAPI) => {
    const url = `${modulePrefix}/`;
    const data = await handlePostDispatchWithAvatar({
      thunkAPI,
      url,
      postData,
      showNoti: true,
    });
    return data;
  },
);

const updateGroup = createAsyncThunk(
  'updateGroup',
  async (putData, thunkAPI) => {
    delete putData.owner;
    const url = `${modulePrefix}/${putData.id}/`;
    const data = await handlePutDispatchWithAvatar({
      thunkAPI,
      url,
      putData,
      showNoti: true,
    });
    return data;
  },
);

const retrieveGroup = createAsyncThunk(
  'retrieveGroup',
  async (id, thunkAPI) => {
    const url = `${modulePrefix}/${id}`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
      showNoti: false,
    });
    return data;
  },
);

const getMyGroups = createAsyncThunk('getAllGroups', async (_, thunkAPI) => {
  const url = modulePrefix;
  const { results } = await handleGetDispatch({
    thunkAPI,
    url,
  });
  return results;
});

const getJoinedGroups = createAsyncThunk(
  'getJoinedGroups',
  async (_, thunkAPI) => {
    const url = `${modulePrefix}/joinedList/`;
    const { results } = await handleGetDispatch({
      thunkAPI,
      url,
    });
    return results;
  },
);

const deleteGroup = createAsyncThunk(
  'deleteProject',
  async ({ id }, thunkAPI) => {
    const url = `${modulePrefix}/${id}/`;
    const data = await handleDeleteDispatch({
      thunkAPI,
      url,
      showNoti: true,
    });
    return data;
  },
);

const inviteMember = createAsyncThunk(
  'inviteMember',
  async ({ groupId, email }, thunkAPI) => {
    const url = `${modulePrefix}/${groupId}/invite/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: { email, redirect: PATH_GROUP.feed(groupId) },
      showNoti: true,
    });
    return data;
  },
);

const getInviteLink = createAsyncThunk(
  'getInviteLink',
  async ({ groupId }, thunkAPI) => {
    const url = `${modulePrefix}/${groupId}/getInviteLink/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: {},
      // showNoti: true
    });
    return data;
  },
);

const joinByLink = createAsyncThunk(
  'joinByLink',
  async ({ invitationId, invitedCode, groupId }, thunkAPI) => {
    const url = `${modulePrefix}/${groupId}/joinByLink/?invitationId=${invitationId}&invitedCode=${invitedCode}`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
      showNoti: true,
    });
    return data;
  },
);

const kickMember = createAsyncThunk(
  'kickMember',
  async ({ group: groupId, id }, thunkAPI) => {
    thunkAPI.dispatch(setIsLoading(true));
    try {
      await axiosAPI.delete(`${modulePrefix}/${groupId}/kick/?memberId=${id}`);
      thunkAPI.dispatch(
        toastifyAction.setMessage({
          message: 'Member Kicked',
          type: 'success',
        }),
      );
      return '';
    } catch (error) {
      let errorMsg = '';
      if (error.response?.data) {
        const { detail, message } = error.response.data;
        errorMsg = detail || message;
      } else errorMsg = error.message || error.msg || error.error || error;
      thunkAPI.dispatch(
        toastifyAction.setMessage({
          message: errorMsg.toString(),
          type: 'error',
        }),
      );
      return thunkAPI.rejectWithValue();
    }
  },
);

const initialState = { myGroups: [], publicGroups: [], joinedGroups: [] };

export const groupSlice = createSlice({
  name: modulePrefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyGroups.fulfilled, (state, action) => {
      state.myGroups = action.payload;
    });
    builder.addCase(getJoinedGroups.fulfilled, (state, action) => {
      state.joinedGroups = action.payload;
    });
  },
});

export const groupActions = groupSlice.actions;

export {
  getMyGroups,
  getJoinedGroups,
  inviteMember,
  kickMember,
  createGroup,
  retrieveGroup,
  deleteGroup,
  updateGroup,
  getInviteLink,
  joinByLink,
};
