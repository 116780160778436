import _ from 'lodash';
import { capitalCase } from 'change-case';
import { toastifyAction } from './toastifySlice';
import { axiosAPI } from '../api/api';

const handleDispatch = async ({
  thunkAPI,
  url,
  method,
  formData,
  config = {},
  showNoti = false,
}) => {
  try {
    let res = {};
    switch (method) {
      case 'get':
        res = await axiosAPI.get(url);
        break;
      case 'delete':
        res = await axiosAPI.delete(url);
        break;
      case 'post':
        res = await axiosAPI.post(url, formData, config);
        break;
      case 'put':
        res = await axiosAPI.put(url, formData, config);
        break;
      default:
        throw Error('Invalid Method');
    }
    const { data } = res;
    if (showNoti)
      thunkAPI.dispatch(
        toastifyAction.setMessage({
          message: data.msg || 'Successfully',
          type: 'success',
        }),
      );
    return data;
  } catch (err) {
    let errorMsg = '';

    if (err.response?.data) {
      const { detail, message, error } = err.response.data;
      errorMsg = error || detail || message;
    } else {
      errorMsg = err.message || err.msg || err.error || err;
    }

    thunkAPI.dispatch(
      toastifyAction.setMessage({
        message: errorMsg ? errorMsg.toString() : 'Oops! Something Wrong',
        type: 'error',
      }),
    );
    return thunkAPI.rejectWithValue();
  }
};

export const handlePostDispatch = async ({
  thunkAPI,
  url,
  postData,
  config = {},
  showNoti = false,
}) =>
  handleDispatch({
    thunkAPI,
    url,
    method: 'post',
    formData: postData,
    showNoti,
  });

export const handlePutDispatch = async ({
  thunkAPI,
  url,
  putData,
  showNoti = false,
}) =>
  handleDispatch({ thunkAPI, url, method: 'put', formData: putData, showNoti });

export const handlePostDispatchWithAvatar = async ({
  thunkAPI,
  url,
  postData: data,
  showNoti = false,
}) => {
  if (!(data.avatar instanceof File)) delete data.avatar;
  if (!(data.cover instanceof File)) delete data.cover;
  const formData = new FormData();
  Object.keys(data).map((key) => formData.append(key, data[key]));
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return handleDispatch({
    thunkAPI,
    url,
    method: 'post',
    formData,
    config,
    showNoti,
  });
};

export const handlePutDispatchWithAvatar = async ({
  thunkAPI,
  url,
  putData,
  showNoti = false,
}) => {
  const { avatar, cover, image, ...data } = putData;
  if (avatar instanceof File) data.avatar = avatar;
  if (cover instanceof File) data.cover = cover;
  if (image instanceof File) data.image = image;

  const formData = new FormData();
  Object.keys(data).map((key) => formData.append(key, data[key]));
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return handleDispatch({
    thunkAPI,
    url,
    method: 'put',
    formData,
    config,
    showNoti,
  });
};

export const handleGetDispatch = async ({ thunkAPI, url, showNoti = false }) =>
  handleDispatch({ thunkAPI, url, method: 'get', showNoti });

export const handleDeleteDispatch = async ({
  thunkAPI,
  url,
  showNoti = false,
}) => handleDispatch({ thunkAPI, url, method: 'delete', showNoti });
