import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  Grid,
  Card,
  Button,
  Avatar,
  Typography,
  InputAdornment,
  Popover,
  MenuItem,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/system';
// components
import Iconify from '../../../components/Iconify';
import InputStyle from '../../../components/InputStyle';
import InviteModal from '../modal/InviteModal';
import { appDispatch } from '../../../store/appDispatch';
import {
  getInviteLink,
  inviteMember,
  kickMember,
  updateGroup,
} from '../../../store/groupSlice';
import InviteLinkModal from '../modal/InviteLinkModal';
import { PATH_GROUP } from '../../../routes/paths';
import { setShowPermissionDeniedModal } from '../../../store/modalDispatch';

// ----------------------------------------------------------------------

GroupMembers.propTypes = {
  group: PropTypes.object,
  isOwnerOrCoOwner: PropTypes.bool,
  isMember: PropTypes.bool,
  refresh: PropTypes.func,
};

export default function GroupMembers({
  group,
  isOwnerOrCoOwner,
  isMember,
  refresh,
}) {
  const [query, setQuery] = useState('');
  const [isOpen, setIsInviteModalOpen] = useState(false);
  const [isShareLinkOpen, setIsShareLinkModalOpen] = useState(false);

  const [open, setOpen] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentMember, setCurrentMember] = useState(null);
  const [link, setLink] = useState();

  const handleOpenMenu = (event, user, member) => {
    if (!isOwnerOrCoOwner) return;
    setOpen(event.currentTarget);
    setCurrentMember(member);
    setCurrentUser(user);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const dispatch = useDispatch();

  const onClose = () => {
    setIsInviteModalOpen(false);
  };

  const onShareLinkClose = () => {
    setIsShareLinkModalOpen(false);
  };

  const invite = async (email) => {
    onClose();
    await appDispatch(dispatch, inviteMember({ groupId: group.id, email }));
  };

  useEffect(() => {}, [query]);

  const assignCoOwner = async (user, group) => {
    handleCloseMenu();
    await appDispatch(dispatch, updateGroup({ ...group, coOwner: user.email }));
    await refresh();
  };

  const unAssignCoOwner = async (user, group) => {
    delete group.coOwner;
    handleCloseMenu();
    await appDispatch(dispatch, updateGroup(group));
    await refresh();
  };

  const kick = async (member) => {
    handleCloseMenu();
    await appDispatch(dispatch, kickMember(member));
    await refresh();
  };

  const getLink = async () => {
    const { payload } = await dispatch(getInviteLink({ groupId: group.id }));
    const link = `${window.location.host}${PATH_GROUP.join(payload)}`;
    setLink(link);
  };

  const onInviteBtnClick = () => {
    if (isMember || isOwnerOrCoOwner) setIsInviteModalOpen(true);
    else dispatch(setShowPermissionDeniedModal(true));
  };

  const onShareLinkBtnClick = () => {
    if (isMember || isOwnerOrCoOwner) {
      setIsShareLinkModalOpen(true);
      getLink();
    } else dispatch(setShowPermissionDeniedModal(true));
  };

  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Members
        </Typography>

        <InviteModal isOpen={isOpen} onClose={onClose} onSubmit={invite} />
        <InviteLinkModal
          isOpen={isShareLinkOpen}
          onClose={onShareLinkClose}
          link={link}
          onSubmit={invite}
        />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputStyle
            stretchStart={240}
            // value={query}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Find members..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              // ml: 2,
              // mr: 1,
              // p: 3,
              mb: 3,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) =>
                  `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />

          <Stack spacing={1} direction="row" alignItems="center">
            <Button variant="contained" size="large" onClick={onInviteBtnClick}>
              Invite
            </Button>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={onShareLinkBtnClick}
            >
              Share Link
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={3}>
          {
            <Grid key={group?.owner?.id} item xs={12} md={4}>
              <MemberCard user={group?.owner} isOwner />
            </Grid>
          }
          {group?.coOwner ? (
            <Grid key={group.coOwner.id} item xs={12} md={4}>
              <MemberCard
                user={group.coOwner}
                hasAccess={isOwnerOrCoOwner}
                isCoOwner
                onOpenMenu={handleOpenMenu}
              />
            </Grid>
          ) : null}
          {applyFilter(group?.members, query)?.map((member) => (
            <Grid key={member.user.id} item xs={12} md={4}>
              <MemberCard
                user={member.user}
                member={member}
                memberId={member.id}
                hasAccess={isOwnerOrCoOwner}
                onOpenMenu={handleOpenMenu}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {currentUser?.id === group?.coOwner?.id ? (
          <MenuItem
            onClick={() => {
              unAssignCoOwner(currentUser, group);
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Unassign Co-Owner
          </MenuItem>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                assignCoOwner(currentUser, group);
              }}
            >
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Assign Co-Owner
            </MenuItem>
            <MenuItem
              sx={{ color: 'error.main' }}
              onClick={() => {
                kick(currentMember);
              }}
            >
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Kick
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

MemberCard.propTypes = {
  user: PropTypes.object,
  member: PropTypes.object,
};

function MemberCard({
  user,
  member,
  isOwner,
  isCoOwner,
  onOpenMenu,
  hasAccess,
}) {
  // const [toggle, setToogle] = useState(isFollowed);
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Avatar
        alt={user?.email}
        src={user?.avatar}
        sx={{ width: 48, height: 48 }}
      />
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {`${user?.lastName} ${user?.firstName}`}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Iconify
            icon={'eva:pin-fill'}
            sx={{ width: 16, height: 16, mr: 0.5, flexShrink: 0 }}
          />
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>
      </Box>
      <Button
        size="small"
        onClick={(e) => hasAccess && onOpenMenu(e, user, member)}
        variant={'text'}
        color={isOwner || isCoOwner ? 'primary' : 'secondary'}
        startIcon={
          isOwner || isCoOwner ? <Iconify icon={'eva:checkmark-fill'} /> : null
        }
      >
        {isOwner ? 'Owner' : isCoOwner ? 'CoOwner' : 'Member'}
      </Button>
    </Card>
  );
}

function applyFilter(array, query) {
  if (query) {
    return array?.filter(
      (member) =>
        `${member.user.lastName} ${member.user.firstName}`
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1,
    );
  }

  return array;
}
