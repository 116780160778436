import React, { createContext } from 'react';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { SERVER_HOST } from './api/api';
import {
  pushMessage,
  pushQuestion,
  setSession,
  updateQuestion,
} from './store/presentationSessionSlice';

const WebSocketContext = createContext(null);

export { WebSocketContext };

export default ({ children }) => {
  let socket;
  let ws;

  const dispatch = useDispatch();

  if (!socket) {
    socket = io.connect(SERVER_HOST, { transports: ['websocket'] });

    socket.on('update_presentation_session', (session) => {
      console.log(session);
      if (session) dispatch(setSession(session));
    });

    socket.on('presentation_session_chat', (message) => {
      if (!message) return;
      dispatch(pushMessage(message));
    });

    socket.on('presentation_session_question', (question) => {
      if (!question) return;
      dispatch(pushQuestion(question));
    });

    socket.on('update_presentation_session_question', (question) => {
      if (!question) return;
      dispatch(updateQuestion(question));
    });

    ws = {
      socket,
    };
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};
