import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Page from '../../components/Page';
import { SkeletonKanbanColumn } from '../../components/skeleton';
import { HEADER } from '../../config';
import {
  SlideColumn,
  SlideInfoColumn,
} from '../../sections/presentation/slide';
import {
  retrievePresentation,
  setCurrentSlide,
} from '../../store/presentationSlice';
import { PATH_PAGE } from '../../routes/paths';
import Image from '../../components/Image';
import Slide from './Slide';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
  margin: 0,
}));

export default function Presentation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const groupId = params.get('groupId');

  const theme = useTheme();

  const { id } = useParams();
  const [presentation, setPresentation] = useState();

  const { currentPresentation, currentSlide } = useSelector(
    (state) => state.presentations,
  );

  const fetchPresentation = async (id) => {
    const { payload, type, error } = await dispatch(retrievePresentation(id));
    if (error) navigate(PATH_PAGE.page404);
    else {
      setPresentation(payload);
      if (payload.slides?.length) dispatch(setCurrentSlide(payload?.slides[0]));
    }
  };

  const slides = currentPresentation?.slides;

  useEffect(() => {
    if (id) fetchPresentation(id);
  }, [id]);

  return (
    <RootStyle>
      <Page title="Presentation" sx={{ height: 1 }}>
        <Grid container height="100%">
          <Grid item xs={2} md={2} lg={1.5} height="100%">
            <SlideColumn slides={slides} />
          </Grid>

          <Grid item xs={6} md={6} lg={8}>
            <Slide slide={currentSlide} />
          </Grid>

          <Grid item xs={4} md={4} lg={2.5} height="100%">
            <SlideInfoColumn />
          </Grid>
        </Grid>
      </Page>
    </RootStyle>
  );
}
