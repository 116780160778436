import { Outlet } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import PresentationHeader from './header';

export default function PresentationLayout() {
  const { themeLayout } = useSettings();

  const verticalLayout = themeLayout === 'vertical';

  return (
    <>
      <PresentationHeader verticalLayout={verticalLayout} />
      <Outlet />
    </>
  );
}
