import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
// components
import Image from '../../../components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
};

export default function ChatMessageItem({ message }) {
  const { user } = useSelector((state) => state.user);

  const sender = message?.user;

  const isMe = user?.id === message?.user?.id;

  const isImage = false;

  const senderName = sender
    ? `${sender?.lastName} ${sender?.firstName}`
    : 'Anonymous';
  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          ...(isMe && {
            ml: 'auto',
          }),
        }}
      >
        {!isMe && (
          <Avatar
            alt={sender?.firstName}
            src={sender?.avatar}
            sx={{ width: 32, height: 32, mr: 2 }}
          />
        )}

        <div>
          <InfoStyle
            variant="caption"
            sx={{
              ...(isMe && { justifyContent: 'flex-end' }),
            }}
          >
            {!isMe && `${senderName},`}&nbsp;
            {moment(new Date(message.createdAt)).fromNow()}
          </InfoStyle>

          <ContentStyle
            sx={{
              ...(isMe && { color: 'grey.800', bgcolor: 'primary.lighter' }),
              ...(isImage && { p: 0 }),
            }}
          >
            {isImage ? (
              <Image
                alt="attachment"
                src={message.body}
                sx={{
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': { opacity: 0.8 },
                }}
              />
            ) : (
              <Typography variant="body2">{message?.message}</Typography>
            )}
          </ContentStyle>
        </div>
      </Box>
    </RootStyle>
  );
}
