import { setIsLoading } from './loadingSlice';

export const appDispatch = async (dispatch, action) => {
  await dispatch(setIsLoading(true));
  const res = await dispatch(action);
  setTimeout(async () => {
    await dispatch(setIsLoading(false));
  }, 500);
  return res;
};
