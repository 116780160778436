// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USER = '/user';
const ROOTS_GROUP = '/group';
const ROOTS_PRESENTATION = '/presentation';
const ROOTS_PRESENTATION_HISTORIES = '/presentation-histories';
const ROOTS_PRESENTATION_SESSION = '/presentation-session';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {},
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),

  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-blazer-low-77-vintage/edit',
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-air-force-1-ndestrukt',
    ),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit',
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    ),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(
      ROOTS_DASHBOARD,
      '/blog/post/apply-these-7-secret-techniques-to-improve-event',
    ),
  },
};

export const PATH_USER = {
  root: path(ROOTS_USER, '/user'),
  new: path(ROOTS_USER, '/new'),
  list: path(ROOTS_USER, '/list'),
  cards: path(ROOTS_USER, '/cards'),
  profile: path(ROOTS_USER, '/profile'),
  account: path(ROOTS_USER, '/account'),
  edit: (name) => path(ROOTS_USER, `/${name}/edit`),
  demoEdit: path(ROOTS_USER, `/reece-chung/edit`),
};

export const PATH_GROUP = {
  root: path(ROOTS_GROUP, '/'),
  new: path(ROOTS_GROUP, '/new'),
  edit: (id) => path(ROOTS_GROUP, `/${id}/edit`),
  feed: (id) => path(ROOTS_GROUP, `/${id}`),
  join: ({ invitationId, invitedCode, groupId }) =>
    path(
      ROOTS_GROUP,
      `/${groupId}/join?invitationId=${invitationId}&invitedCode=${invitedCode}`,
    ),
};

export const PATH_PRESENTATION = {
  root: path(ROOTS_PRESENTATION, '/'),
  new: path(ROOTS_PRESENTATION, '/new'),
  edit: (id) => path(ROOTS_PRESENTATION, `/${id}/edit`),
  slide: (id, group = null) =>
    path(ROOTS_PRESENTATION, group ? `/${id}?group=${group}` : `/${id}`),
  group: (group) => path(ROOTS_PRESENTATION, group ? `/?group=${group}` : '/'),
  histories: (id) => path(ROOTS_PRESENTATION_HISTORIES, `/${id}`),
  historyResults: (id) => path(ROOTS_PRESENTATION_HISTORIES, `/${id}/results`),
};

export const PATH_PRESENTATION_SESSION = {
  root: (id) => path(ROOTS_PRESENTATION_SESSION, `/${id}`),
  visitor: (id) => path(ROOTS_PRESENTATION_SESSION, `/visitor/${id}`),
};
