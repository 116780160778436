import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
// routes
import { PATH_GROUP, PATH_PAGE } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
// sections
import GroupForm from '../../sections/group/GroupForm';
import { retrieveGroup } from '../../store/groupSlice';
import { appDispatch } from '../../store/appDispatch';

// ----------------------------------------------------------------------

export default function GroupCreateOrEdit() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [group, setGroup] = useState();
  const isEdit = pathname.includes('edit');

  const fetchGroup = async (id) => {
    const { payload, error } = await appDispatch(dispatch, retrieveGroup(id));
    if (error) return navigate(PATH_PAGE.page404);
    return setGroup(payload);
  };

  useEffect(() => {
    if (id && isEdit) fetchGroup(id);
  }, [id]);

  return (
    <Page title="Group">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create A New Group' : 'Edit Group'}
          links={[
            { name: 'Group', href: PATH_GROUP.root },
            { name: !isEdit ? 'New Group' : capitalCase(group?.name || '') },
          ]}
        />

        <GroupForm isEdit={isEdit} currentGroup={group} />
      </Container>
    </Page>
  );
}
