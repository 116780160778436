import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Input,
  Portal,
  Stack,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
import ChatWindow from './ChatWindow';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  // right: 0,
  // bottom: 0,
  zIndex: 1999,
  // minHeight: 440,
  left: '50%',
  top: '50%',
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.paper,
  transform: `translate(-50%, -50%)`,
}));

// ----------------------------------------------------------------------

ChatModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default function ChatModal({ isOpen, onClose, onSubmit }) {
  const [fullScreen, setFullScreen] = useState(true);

  const isDesktop = useResponsive('up', 'sm');

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <Backdrop open={fullScreen || !isDesktop} sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          ...(fullScreen && {
            justifyContent: 'center',
            zIndex: 1999,
          }),
        }}
      >
        <Card sx={{ height: '72vh', padding: '15px', display: 'flex' }}>
          <ChatWindow />
          <CardContent sx={{ p: 0 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon={'eva:close-fill'} width={20} height={20} />
            </IconButton>
          </CardContent>
        </Card>
      </RootStyle>
    </Portal>
  );
}
