import { capitalCase } from 'change-case';
import { useContext, useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Tab,
  Box,
  Card,
  Tabs,
  Container,
  Link,
  Alert,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams,
  Link as RouterLink,
  Navigate,
  useNavigate,
} from 'react-router-dom';
// routes
import {
  PATH_DASHBOARD,
  PATH_GROUP,
  PATH_PAGE,
  PATH_PRESENTATION_SESSION,
} from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import {
  _userAbout,
  _userFeeds,
  _userFriends,
  _userGallery,
  _userFollowers,
} from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import {
  Profile,
  ProfileCover,
  ProfileFriends,
  // ProfileGallery,
  GroupMembers,
} from '../../sections/group/profile';
import { retrieveGroup } from '../../store/groupSlice';
import { appDispatch } from '../../store/appDispatch';
import PermissionDeniedContainer from '../../sections/PermissionDeniedContainer';
import { WebSocketContext } from '../../SocketContext';

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function GroupFeed() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  const { id } = useParams();
  const [group, setGroup] = useState();

  const [currentTab, setCurrentTab] = useState('profile');
  const [findFriends, setFindFriends] = useState('');

  const { socket } = useContext(WebSocketContext);

  useEffect(() => {
    if (!socket || !id) return;
    socket.emit('join_group', { id: +id });
    socket.on('update_group', (group) => {
      if (group) setGroup(group);
    });
  }, [socket, id]);

  const fetchGroup = async (id) => {
    const { payload, error } = await appDispatch(dispatch, retrieveGroup(id));
    if (error) return navigate(PATH_PAGE.page404);
    return setGroup(payload);
  };

  const isOwnerOrCoOwner =
    user?.id === group?.owner?.id || user?.id === group?.coOwner?.id;
  const isMember = group?.members?.some(
    (member) => member.user?.id === user?.id || member?.id === user?.id,
  );

  useEffect(() => {
    if (id) fetchGroup(id);
  }, [id]);

  const handleChangeTab = (newValue) => {
    setCurrentTab(newValue);
  };

  const handleFindFriends = (value) => {
    setFindFriends(value);
  };

  const PROFILE_TABS = [
    {
      value: 'profile',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <Profile group={group} posts={_userFeeds} />,
    },
    {
      value: 'members',
      icon: <Iconify icon={'eva:heart-fill'} width={20} height={20} />,
      component: (
        <GroupMembers
          group={group}
          isOwnerOrCoOwner={isOwnerOrCoOwner}
          isMember={isMember}
          refresh={() => fetchGroup(id)}
        />
      ),
    },
    {
      value: 'setting',
      icon: <Iconify icon={'ic:round-perm-media'} width={20} height={20} />,
      component: isOwnerOrCoOwner ? (
        <Navigate to={PATH_GROUP.edit(group?.id)} />
      ) : (
        <PermissionDeniedContainer />
      ),
    },
    // {
    //   value: 'friends',
    //   icon: <Iconify icon={'eva:people-fill'} width={20} height={20} />,
    //   component: (
    //     <ProfileFriends
    //       friends={_userFriends}
    //       findFriends={findFriends}
    //       onFindFriends={handleFindFriends}
    //     />
    //   ),
    // },

    // {
    //   value: 'gallery',
    //   icon: <Iconify icon={'ic:round-perm-media'} width={20} height={20} />,
    //   component: <ProfileGallery gallery={_userGallery} />,
    // },
  ];

  const OWNER_PROFILE_TABS = [...PROFILE_TABS];

  const canEdit = (user, group) =>
    user?.id === group?.owner?.id || user?.id === group?.coOwner?.id;

  return (
    <Page title={`Group ${group?.name}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        {/* <HeaderBreadcrumbs
          heading={group?.name}
          links={[
            { name: 'Group', href: PATH_GROUP.root },
            { name: group?.name || '' },
          ]}
        /> */}
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative',
          }}
        >
          <ProfileCover group={group} />

          <TabsWrapperStyle>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(e, value) => handleChangeTab(value)}
            >
              {(canEdit(user, group) ? OWNER_PROFILE_TABS : PROFILE_TABS).map(
                (tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={capitalCase(tab.value)}
                  />
                ),
              )}
            </Tabs>
          </TabsWrapperStyle>
        </Card>
        {group?.presentationSession && (
          <Alert sx={{ mb: 2 }}>
            There is a presentation session{' '}
            <Button
              onClick={() =>
                navigate(
                  PATH_PRESENTATION_SESSION.visitor(
                    group?.presentationSession?.id ||
                      group?.presentationSession,
                  ),
                )
              }
            >
              Join Now
            </Button>
          </Alert>
        )}
        {(canEdit(user, group) ? OWNER_PROFILE_TABS : PROFILE_TABS).map(
          (tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          },
        )}
      </Container>
    </Page>
  );
}
