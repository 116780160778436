import axios from 'axios';

export const SERVER_HOST =
  process.env.REACT_APP_SERVER_HOST || 'http://localhost:8080';

export const API_BASE =
  process.env.REACT_APP_API || 'http://localhost:8080/api';

export const axiosAPI = axios.create({ baseURL: API_BASE });
axiosAPI.interceptors.request.use((req) => {
  if (!req.headers['Content-Type'])
    req.headers['Content-Type'] = 'application/json';
  const accessToken = localStorage.getItem('accessToken');
  req.headers['Access-Control-Allow-Origin'] = '*';
  req.headers['Access-Control-Allow-Headers'] = 'X-Requested-With';
  req.headers['ngrok-skip-browser-warning'] = 'true';

  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
  }

  return req;
});

// export const sendResetPasswordEmail = async (email) => axiosAPI.post(`${API_BASE}/auth/`)

export const getSlideById = async (id) =>
  axiosAPI.get(`${API_BASE}/quiz/${id}`);

export const voteById = async (optionID) =>
  axiosAPI.post(`${API_BASE}/quiz/vote/`, { option: optionID });

export const unVoteById = async (optionID) =>
  axiosAPI.post(`${API_BASE}/quiz/vote/unvote/`, { option: optionID });
