import { useTheme } from '@emotion/react';
import { Box, Container, Typography } from '@mui/material';
import Image from '../../components/Image';
import OptionVoteBarChart from '../../sections/presentation/slide/BarChart';

export default function Slide({ slide }) {
  const theme = useTheme();
  if (!slide) return <></>;
  return (
    <Box sx={{ position: 'relative', p: 10, bgColor: theme.palette.grey[100] }}>
      {slide?.slideType === 'QUIZ' ? (
        <QuizSlide slide={slide} />
      ) : (
        <HeadingSlide slide={slide} />
      )}

      <Box
        sx={{
          position: 'absolute',
          textAlign: 'center',
          p: 10,
          width: '80%',
          height: '80%',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%)`,
        }}
      >
        {slide?.slideType === 'QUIZ' && <OptionVoteBarChart slide={slide} />}
      </Box>

      <Image
        src={
          slide?.image?.preview ||
          slide?.image ||
          '/assets/slide_placeholder.jpg'
        }
        ratio="16/9"
        sx={{ zIndex: -100 }}
      />
    </Box>
  );
}

const QuizSlide = ({ slide }) => (
  <>
    <Box sx={{ position: 'absolute', p: 2 }}>
      <Typography variant="h3">{slide?.header}</Typography>
      <Typography variant="h6">{slide?.subHeader}</Typography>
    </Box>
  </>
);

const HeadingSlide = ({ slide }) => (
  <>
    <Box
      sx={{
        position: 'absolute',
        textAlign: 'center',
        p: 10,
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
      }}
    >
      <Typography variant="h3">{slide?.header}</Typography>
      <Typography variant="h6">{slide?.subHeader}</Typography>
    </Box>
  </>
);
