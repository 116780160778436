import { createSlice } from '@reduxjs/toolkit';

const initialState = { showPermissionDeniedModal: false };

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalState: (state, action) => ({ ...state, ...action.payload }),
    setShowPermissionDeniedModal: (state, action) => ({
      ...state,
      showPermissionDeniedModal: action.payload,
    }),
  },
});

export const { setModalState, setShowPermissionDeniedModal } =
  modalSlice.actions;
