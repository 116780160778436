import PropTypes from 'prop-types';
// @mui
import { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
//
import GroupProfileAbout from './GroupProfileAbout';
import ProfilePostCard from './ProfilePostCard';
import GroupPostInput from './GroupPostInput';
import GroupMemberPresentationInfo from './GroupMemberPresentationInfo';
import ProfileSocialInfo from './ProfileSocialInfo';

// ----------------------------------------------------------------------

GroupProfile.propTypes = {
  group: PropTypes.object,
  posts: PropTypes.array,
};

export default function GroupProfile({ group, posts }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          <GroupMemberPresentationInfo group={group} />
          <GroupProfileAbout group={group} />
          {/* <ProfileSocialInfo group={group} /> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <GroupPostInput group={group} />
          {posts.map((post) => (
            <ProfilePostCard key={post.id} post={post} />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
