import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './authSlice';
import { toastifySlice } from './toastifySlice';
import { loadingSlice } from './loadingSlice';
import { userSlice } from './userSlice';
import { groupSlice } from './groupSlice';
import { presentationSlice } from './presentationSlice';
import { modalSlice } from './modalDispatch';
import { presentationSessionSlice } from './presentationSessionSlice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    auth: authSlice.reducer,
    groups: groupSlice.reducer,
    presentations: presentationSlice.reducer,
    presentationSession: presentationSessionSlice.reducer,
    toastify: toastifySlice.reducer,
    isLoading: loadingSlice.reducer,
    modal: modalSlice.reducer,
  },
});
