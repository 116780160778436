import { useRef } from 'react';
// @mui
import { Box, Button, Card, IconButton, Stack, TextField } from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../components/Iconify';
import { PATH_PRESENTATION } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function GroupPostInput({ group }) {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleAttach = () => {
    fileInputRef.current?.click();
  };

  const navigateToSlide = async () => {
    navigate(PATH_PRESENTATION.group(group?.id));
  };

  return (
    <Card sx={{ p: 3 }}>
      <TextField
        multiline
        fullWidth
        rows={4}
        placeholder="Share what you are thinking here..."
        sx={{
          '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
          },
        }}
      />

      <Box
        sx={{
          mt: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <IconButton size="small" onClick={handleAttach} sx={{ mr: 1 }}>
            <Iconify
              icon={'ic:round-add-photo-alternate'}
              width={24}
              height={24}
            />
          </IconButton>
          <IconButton size="small" onClick={handleAttach}>
            <Iconify icon={'eva:attach-2-fill'} width={24} height={24} />
          </IconButton>
        </Box>
        <Stack direction="row">
          <Button variant="contained" sx={{ mr: 1 }} color="secondary">
            Post
          </Button>
          <Button
            variant="contained"
            startIcon={<Iconify icon="carbon:group-presentation" />}
            onClick={navigateToSlide}
          >
            Present Now
          </Button>
        </Stack>
      </Box>

      <input ref={fileInputRef} type="file" style={{ display: 'none' }} />
    </Card>
  );
}
