import {
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { SERVER_HOST } from '../../api/api';

import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import { PATH_PRESENTATION_SESSION } from '../../routes/paths';
import ChatModal from '../../sections/presentation/chat/ChatModal';
import ChatWindow from '../../sections/presentation/chat/ChatWindow';
import QuestionModal from '../../sections/presentation/question/QuestionModal';
import OptionVoteBarChart from '../../sections/presentation/slide/BarChart';
import { WebSocketContext } from '../../SocketContext';
import {
  fetchChat,
  fetchQuestions,
  pushMessage,
  sendVote,
  setIsChatOpen,
  setIsQuestionOpen,
  setSession,
  setUnreadMsgCount,
  setUnreadQuestionCount,
  updatePresentationSession,
} from '../../store/presentationSessionSlice';

export default function SlideRealtimeVisitor({ slide }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    session,
    unreadMsgCount,
    isChatOpen,
    chatNextPage,
    unreadQuestionCount,
    isQuestionOpen,
    questionNextPage,
  } = useSelector((state) => state.presentationSession);

  const { socket } = useContext(WebSocketContext);

  const setChatModalOpen = (val) => {
    dispatch(setIsChatOpen(val));
  };

  const setQuestionModalOpen = (val) => {
    dispatch(setIsQuestionOpen(val));
  };

  useEffect(() => {
    if (!session.id) return;
    dispatch(fetchChat({ id: session.id, page: chatNextPage }));
    dispatch(fetchQuestions({ id: session.id, page: questionNextPage }));
  }, [session.id]);

  useEffect(() => {
    if (isChatOpen) dispatch(setUnreadMsgCount(0));
  }, [isChatOpen]);

  useEffect(() => {
    if (isQuestionOpen) dispatch(setUnreadQuestionCount(0));
  }, [isQuestionOpen]);

  useEffect(() => {
    if (!socket || !session.id) return;
    socket.emit('join_presentation_session', { id: session.id });
  }, [socket, session]);

  if (!slide) return <></>;

  const submitOption = async (option) => {
    await dispatch(
      sendVote({
        id: session.id,
        option: { option: option.id, slide: slide.id },
      }),
    );
  };

  const copyLink = () => {
    copy(window.location.host + PATH_PRESENTATION_SESSION.visitor(session.id));
  };

  return (
    <Box sx={{ position: 'relative', p: 0, bgColor: theme.palette.grey[100] }}>
      {slide?.slideType === 'QUIZ' ? (
        <QuizSlide slide={slide} />
      ) : (
        <HeadingSlide slide={slide} />
      )}
      <Box
        sx={{
          position: 'absolute',
          textAlign: 'center',
          p: 10,
          width: '80%',
          height: '80%',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%)`,
        }}
      >
        {slide?.slideType === 'QUIZ' && (
          <OptionVotes options={slide?.options} onSubmit={submitOption} />
        )}
      </Box>

      <ChatModal isOpen={isChatOpen} onClose={() => setChatModalOpen(false)} />
      <QuestionModal
        isOpen={isQuestionOpen}
        onClose={() => setQuestionModalOpen(false)}
        isViewer
      />

      <Stack
        direction="row"
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: '10%',
          my: 5,
          alignItems: 'center',
        }}
      >
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => setChatModalOpen(true)}
        >
          <Badge badgeContent={unreadMsgCount} color="error">
            {' '}
            <Iconify icon={'material-symbols:chat'} />
          </Badge>
        </IconButton>
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => setQuestionModalOpen(true)}
        >
          <Badge badgeContent={unreadQuestionCount} color="error">
            {' '}
            <Iconify icon={'mdi:chat-question'} />
          </Badge>
        </IconButton>
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => copyLink()}
        >
          <Iconify icon={'mdi:link-box-variant-outline'} />
        </IconButton>
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => document.documentElement.requestFullscreen()}
        >
          <Iconify icon={'material-symbols:fullscreen'} />
        </IconButton>
      </Stack>

      <Image
        src={
          slide?.image?.preview ||
          slide?.image ||
          '/assets/slide_placeholder.jpg'
        }
        ratio="16/9"
        sx={{ zIndex: -100 }}
      />
    </Box>
  );
}

const QuizSlide = ({ slide }) => (
  <>
    <Box sx={{ position: 'absolute', p: 2 }}>
      <Typography variant="h3">{slide?.header}</Typography>
      <Typography variant="h6">{slide?.subHeader}</Typography>
    </Box>
  </>
);

const HeadingSlide = ({ slide }) => (
  <>
    <Box
      sx={{
        position: 'absolute',
        textAlign: 'center',
        p: 10,
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
      }}
    >
      <Typography variant="h3">{slide?.header}</Typography>
      <Typography variant="h6">{slide?.subHeader}</Typography>
    </Box>
  </>
);

const OptionVotes = ({ options, onSubmit }) => {
  const { user } = useSelector((state) => state.user);

  const hasSubmit = options.some((option) =>
    option.votes.some((vote) => vote.user === user.id),
  );
  const [checkOption, setCheck] = useState(
    options.find((option) =>
      option.votes.some((vote) => vote.user === user.id),
    ),
  );

  const handleSubmit = (checkOption) => {
    // setHasSubmit(true)
    onSubmit(checkOption);
  };

  // useEffect(() => { setHasSubmit(false) }, [options])

  return (
    <>
      {options?.map((option) => (
        <Stack key={option.id} direction="row" sx={{ p: 1 }}>
          <Checkbox
            disabled={hasSubmit}
            checked={checkOption?.id === option.id}
            onChange={(e) =>
              setCheck(e.target.checked === true ? option : null)
            }
          />
          <OutlinedInput
            fullWidth
            // multiline
            disabled
            size="small"
            value={option?.title || ''}
            sx={{
              typography: 'h7',
              // '& .MuiOutlinedInput-notchedOutline': {
              //     borderColor: 'transparent',
              // },
            }}
          />
        </Stack>
      ))}
      {!hasSubmit && (
        <Button
          fullWidth
          variant="contained"
          onClick={() => handleSubmit(checkOption)}
        >
          Submit
        </Button>
      )}
    </>
  );
};
