import { Backdrop } from '@mui/material';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// import { HashRouter } from 'react-router-dom';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import ThemeColorPresets from './components/ThemeColorPresets';
import Router from './routes/routes';
import { loadAuth, refresh } from './store/authSlice';
import { toastifyAction } from './store/toastifySlice';
import { getUserInfo } from './store/userSlice';
import ThemeProvider from './theme';
import RtlLayout from './components/RtlLayout';

// components
import NotistackProvider from './components/NotistackProvider';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import Settings from './components/settings';
import PermissionDeniedModal from './sections/PermissionDeniedModal';
import WebSocketProvider from './SocketContext';
// ----------------------------------------------------------------------

export default function App() {
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const toastify = useSelector((state) => state.toastify);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const load = async () => {};
    load();
  }, []);

  useEffect(() => {
    dispatch(loadAuth());
  }, [dispatch]);

  useEffect(() => {
    const { message, type } = toastify;
    if (!message) return;
    toast(message, { type });
    dispatch(toastifyAction.clearMessage());
  }, [toastify]);

  useEffect(() => {
    if (!auth?.isAuth) return;
    dispatch(getUserInfo());
    const { exp } = jwtDecode(auth.accessToken);
    if (exp < new Date().getTime() / 1000) dispatch(refresh());
  }, [auth.isAuth]);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <PermissionDeniedModal />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <WebSocketProvider>
                  <Router />
                </WebSocketProvider>
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isLoading}
                >
                  <TailSpin color="#fff" />
                </Backdrop>
                <ToastContainer />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
