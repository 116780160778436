import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useSelector((state) => state.user);

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        textAlign: 'center',
        display: 'block',
      }}
    >
      <DocIllustration sx={{ width: 1 }} />

      <div>
        {/* <Typography gutterBottom variant="subtitle1">
          Hi, {`${user?.lastName} ${user?.firstName}`}
        </Typography> */}
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <br /> Mentimeter©2022
        </Typography>
      </div>
    </Stack>
  );
}
