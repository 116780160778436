import { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Input,
  Portal,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../../../hooks/useResponsive';
import { PATH_PRESENTATION } from '../../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  // right: 0,
  // bottom: 0,
  zIndex: 1999,
  // minHeight: 440,
  left: '50%',
  top: '50%',
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.paper,
  transform: `translate(-50%, -50%)`,
}));

const InputStyle = styled(Input)(({ theme }) => ({
  padding: theme.spacing(0.5, 3),
  // borderBottom: `solid 1px ${theme.palette.divider}`,
}));

// ----------------------------------------------------------------------

export default function NotiEndSessionModal() {
  const navigate = useNavigate();
  const [fullScreen, setFullScreen] = useState(true);

  const isDesktop = useResponsive('up', 'sm');

  return (
    <Portal>
      <Backdrop open={fullScreen || !isDesktop} sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          ...(fullScreen && {
            justifyContent: 'center',
            zIndex: 1999,
            padding: '20px',
          }),
        }}
      >
        <Box
          sx={{
            pl: 3,
            pr: 1,
            height: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">
            This presentation session has been ended!
          </Typography>
          <Button onClick={() => navigate(PATH_PRESENTATION.root)}>
            Go Home
          </Button>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
      </RootStyle>
    </Portal>
  );
}
