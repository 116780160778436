import PropTypes from 'prop-types';
// @mui
import { Backdrop, Box, IconButton, Input, Portal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
// hooks
// components
import Iconify from '../components/Iconify';
import PermissionDeniedContainer from './PermissionDeniedContainer';
import { setShowPermissionDeniedModal } from '../store/modalDispatch';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  // right: 0,
  // bottom: 0,
  zIndex: 1999,
  // minHeight: 440,
  left: '50%',
  top: '50%',
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.paper,
  transform: `translate(-50%, -50%)`,
}));

const InputStyle = styled(Input)(({ theme }) => ({
  padding: theme.spacing(0.5, 3),
  // borderBottom: `solid 1px ${theme.palette.divider}`,
}));

// ----------------------------------------------------------------------

PermissionDeniedModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function PermissionDeniedModal({ isOpen, onClose, onSubmit }) {
  const dispatch = useDispatch();
  const { showPermissionDeniedModal } = useSelector((state) => state.modal);

  const handleClose = () => {
    dispatch(setShowPermissionDeniedModal(false));
  };

  if (!showPermissionDeniedModal) {
    return null;
  }

  return (
    <Portal>
      <Backdrop sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          justifyContent: 'center',
          zIndex: 1999,
          padding: '20px',
        }}
      >
        <Box
          sx={{
            pl: 3,
            pr: 1,
            height: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Box>
        <PermissionDeniedContainer />
      </RootStyle>
    </Portal>
  );
}
