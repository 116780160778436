// @mui
import {
  Container,
  Box,
  Button,
  Stack,
  MenuItem,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import orderBy from 'lodash/orderBy';
// routes
import { PATH_DASHBOARD, PATH_GROUP, PATH_USER } from '../../routes/paths';
// _mock_
import { _userCards } from '../../_mock/_user';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import Iconify from '../../components/Iconify';
import GroupCard from '../../sections/group/cards/GroupCard';
import { appDispatch } from '../../store/appDispatch';
import { getJoinedGroups, getMyGroups } from '../../store/groupSlice';
import { BlogPostsSort } from '../../sections/group/filter';
// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'mine', label: 'Mine' },
  { value: 'co-owner', label: 'Co-Owner' },
  { value: 'member', label: 'Joined' },
  { value: 'other', label: 'Others' },
];

const isOwner = (group, user) =>
  group?.owner?.id === user?.id || group?.owner === user?.id;
const isCoOwner = (group, user) =>
  group?.coOwner?.id === user?.id || group?.coOwner === user?.id;

export default function GroupList() {
  const dispatch = useDispatch();
  const { myGroups, joinedGroups, publicGroups } = useSelector(
    (state) => state.groups,
  );
  const { user } = useSelector((state) => state.user);

  const [filters, setFilters] = useState('mine');

  const fetchGroups = async () => {
    await appDispatch(dispatch, getMyGroups({}));
    await appDispatch(dispatch, getJoinedGroups({}));
  };

  useEffect(() => {
    setTimeout(() => fetchGroups(), 500);
  }, []);

  const handleChangeSort = (e) => {
    setFilters(e.target.value);
  };

  const applySort = (sortBy, user) => {
    switch (sortBy) {
      case 'mine':
        return orderBy(
          myGroups?.filter((group) => isOwner(group, user)),
          ['createdAt'],
          ['desc'],
        );
      case 'co-owner':
        return orderBy(
          myGroups?.filter((group) => isCoOwner(group, user)),
          ['createdAt'],
          ['desc'],
        );
      case 'member':
        return orderBy(joinedGroups, ['createdAt'], ['desc']);
      default:
        return publicGroups;
    }
  };
  return (
    <Page title="Groups">
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs
          heading="Group List"
          links={[{ name: 'Group', href: PATH_GROUP.root }, { name: 'List' }]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_GROUP.new}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New Group
            </Button>
          }
        />
        <Stack
          mb={5}
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <TextField
            select
            size="small"
            value={filters}
            onChange={handleChangeSort}
          >
            {SORT_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ mx: 1, my: 0.5, borderRadius: 1 }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            },
          }}
        >
          {applySort(filters, user)?.map((group) => (
            <GroupCard key={group.id} group={group} />
          ))}
        </Box>
      </Container>
    </Page>
  );
}
