import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Input,
  InputAdornment,
  Portal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  // right: 0,
  // bottom: 0,
  zIndex: 1999,
  // minHeight: 440,
  left: '50%',
  top: '50%',
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.paper,
  transform: `translate(-50%, -50%)`,
}));

const InputStyle = styled(Input)(({ theme }) => ({
  padding: theme.spacing(0.5, 3),
  // borderBottom: `solid 1px ${theme.palette.divider}`,
}));

// ----------------------------------------------------------------------

AddCollaboratorModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function AddCollaboratorModal({ isOpen, onClose, onSubmit }) {
  const [email, setEmail] = useState();

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <Backdrop sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          justifyContent: 'center',
          zIndex: 1999,
          padding: '20px',
        }}
      >
        <Box
          sx={{
            pl: 3,
            pr: 1,
            height: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Add Collaborator</Typography>
          <Box sx={{ flexGrow: 1 }} />

          <IconButton onClick={handleClose}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Box>
        <Stack direction="row" alignItems="center">
          <TextField
            fullwidth
            placeholder="Email ...."
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              ml: 2,
              mr: 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) =>
                  `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />
          <Button
            size="large"
            variant="contained"
            onClick={() => onSubmit(email)}
          >
            Submit
          </Button>
        </Stack>
      </RootStyle>
    </Portal>
  );
}
