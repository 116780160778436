import merge from 'lodash/merge';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, TextField, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
//
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

export default function OptionVoteBarChart({ slide }) {
  const data = slide?.options?.map(({ voteCount }) => voteCount);
  const categories = slide?.options?.map(({ title }) => title || '');

  const chartOptions = merge(BaseOptionChart(), {
    series: [
      {
        name: 'Option',
        data,
      },
    ],
    xaxis: {
      categories,
      labels: {
        style: {
          fontWeight: 500,
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} Votes`,
      },
    },
  });

  return (
    <Box key={slide?.id} sx={{ mt: 3, mx: 3 }} dir="ltr">
      <ReactApexChart
        type="bar"
        series={chartOptions.series}
        options={chartOptions}
      />
    </Box>
  );
}
