import PropTypes from 'prop-types';
// @mui
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Iconify from '../../../components/Iconify';
//
// import ContactsPopover from './ContactsPopover';
import MyAvatar from '../../../components/MyAvatar';
import {
  PATH_PAGE,
  PATH_PRESENTATION,
  PATH_PRESENTATION_SESSION,
} from '../../../routes/paths';
import { startSession } from '../../../store/presentationSessionSlice';
import { retrievePresentation } from '../../../store/presentationSlice';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
    // margin: 0,
  },
}));

// ----------------------------------------------------------------------

PresentationHeader.propTypes = {
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function PresentationHeader({
  isCollapse = false,
  verticalLayout = false,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [params, setParams] = useSearchParams();
  const groupId = +params.get('group');

  const { isSaving } = useSelector((state) => state.presentations);

  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { id } = useParams();
  const [presentation, setPresentation] = useState();

  const fetchPresentation = async (id) => {
    const { payload, error } = await dispatch(retrievePresentation(id));

    if (error) navigate(PATH_PAGE.page404);
    else setPresentation(payload);
  };

  useEffect(() => {
    if (id) fetchPresentation(id);
  }, [id]);

  const start = async () => {
    const { payload } = await dispatch(
      startSession({ id: presentation.id, group: groupId || null }),
    );
    navigate(PATH_PRESENTATION_SESSION.root(payload.id));
  };

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 2 },
        }}
      >
        <IconButton
          size="large"
          onClick={() => navigate(PATH_PRESENTATION.group(groupId))}
        >
          <Iconify icon="material-symbols:arrow-circle-left" />
        </IconButton>
        <Stack>
          <Typography color="text.disabled">{presentation?.name}</Typography>
          <Typography fontSize="12px" color="text.disabled">
            Created by {presentation?.owner?.lastName}{' '}
            {presentation?.owner?.firstName}
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {isSaving ? (
            <TailSpin width={20} />
          ) : (
            <IconButton color="success" size="small">
              <Iconify icon="material-symbols:check" />
            </IconButton>
          )}

          <Typography color="text.disabled">
            {isSaving ? 'Saving ...' : 'Saved'}
          </Typography>

          <Divider orientation="vertical" flexItem />

          <IconButton>
            <MyAvatar alt="avatar" />
          </IconButton>
          <Button
            variant="contained"
            onClick={() => navigate(PATH_PRESENTATION.edit(id))}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={start}
            startIcon={<Iconify icon="ph:presentation-chart-bold" />}
          >
            {groupId ? 'Start Present in this Group' : 'Present'}
          </Button>
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
