import { createSlice } from '@reduxjs/toolkit';

const initialState = { message: '', type: '' };

export const toastifySlice = createSlice({
  name: 'toastify',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state = action.payload;
      return action.payload;
    },
    clearMessage: () => initialState,
  },
});

export const toastifyAction = toastifySlice.actions;
