import PropTypes from 'prop-types';
import { useState, useRef, useEffect, useCallback } from 'react';
// @mui
import { MobileDateRangePicker } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  IconButton,
  List,
  Checkbox,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { capitalCase } from 'change-case';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { IconButtonAnimate } from '../../../components/animate';
//
import KanbanTaskCommentList from './KanbanTaskCommentList';
import SlideImageAttachment from './SlideImageAttachment';
import KanbanTaskCommentInput from './KanbanTaskCommentInput';
import { useDatePicker, DisplayTime } from './KanbanTaskAdd';
import {
  addOption,
  deleteOption,
  deleteSlide,
  deleteSlideAvatar,
  setCurrentSlide,
  setIsSaving,
  setOptionsOrder,
  updateOption,
  updateSlide,
} from '../../../store/presentationSlice';
import DeleteSlideModal from './DeleteModal';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  fontWeight: 50,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

export default function SlideInfoColumn() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { currentSlide, currentPresentation } = useSelector(
    (state) => state.presentations,
  );

  const updateSlideLocal = async (data) => {
    dispatch(setCurrentSlide(data));
  };

  const handleUpdateOption = async ({ option, idx }) => {
    dispatch(setIsSaving(true));
    const options = Object.values(currentSlide?.options);
    options[idx] = option;
    updateOptionLocal({ option, idx });
    await dispatch(updateOption({ id: currentSlide?.id, option }));
    dispatch(setIsSaving(false));
  };

  const updateOptionLocal = async ({ option, idx }) => {
    const options = Object.values(currentSlide?.options);
    options[idx] = option;
    updateSlideLocal({ ...currentSlide, options });
  };

  const update = async (data) => {
    dispatch(setIsSaving(true));
    await updateSlideLocal(data);
    await dispatch(updateSlide({ id: currentPresentation.id, slide: data }));
    dispatch(setIsSaving(false));
  };

  const updateCover = async (image) => {
    dispatch(setIsSaving(true));
    if (!image) {
      await updateSlideLocal({ ...currentSlide, image: null });
      await dispatch(
        deleteSlideAvatar({ id: currentPresentation.id, slide: currentSlide }),
      );
    } else {
      await updateSlideLocal({ ...currentSlide, image: image.preview });
      await dispatch(
        updateSlide({
          id: currentPresentation.id,
          slide: { ...currentSlide, image },
        }),
      );
    }
    dispatch(setIsSaving(false));
  };

  const removeSlide = async () => {
    dispatch(setIsSaving(true));
    setIsDeleteModalOpen(false);
    await dispatch(
      deleteSlide({ id: currentPresentation.id, slide: currentSlide }),
    );
    dispatch(setCurrentSlide(currentPresentation.slides[0]));
    dispatch(setIsSaving(false));
  };

  const createOption = async () => {
    dispatch(setIsSaving(true));
    await dispatch(addOption({ id: currentSlide.id }));
    dispatch(setIsSaving(false));
  };

  const removeOption = async (option) => {
    dispatch(setIsSaving(true));
    await dispatch(deleteOption({ id: currentSlide.id, option }));
    dispatch(setIsSaving(false));
  };

  const updateOptionsOrder = async ({ options }) => {
    updateSlideLocal({ ...currentSlide, options });
    dispatch(setIsSaving(true));
    await dispatch(setOptionsOrder({ id: currentSlide?.id, options }));
    dispatch(setIsSaving(false));
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    const sourceIdx = +source.index;
    const destIdx = +destination.index;
    const newOrder = Object.values(currentSlide?.options);
    const [move] = newOrder.splice(sourceIdx, 1);
    newOrder.splice(destIdx, 0, move);

    updateOptionsOrder({ options: newOrder });
  };

  const getSlideTypeHeader = (slideType) => {
    switch (slideType) {
      case 'QUIZ':
        return (
          <Typography sx={{ color: theme.palette.primary.main }}>
            <Iconify icon={'material-symbols:bar-chart'} sx={{ mr: 2 }} />
            Multiple Choice Slide
          </Typography>
        );
      case 'HEADING':
        return (
          <Typography sx={{ color: theme.palette.secondary.main }}>
            <Iconify icon={'fluent:slide-layout-20-filled'} sx={{ mr: 2 }} />
            Heading
          </Typography>
        );
      default:
        return (
          <Typography sx={{ color: theme.palette.text.main }}>
            <Iconify icon={'fluent:slide-text-16-filled'} sx={{ mr: 2 }} />
            Paragraph
          </Typography>
        );
    }
  };

  const handleCheckOption = ({ checked, option, idx }) => {
    update({ ...currentSlide, correctOption: checked ? option.id : '' });
  };

  if (!currentSlide) return <></>;
  return (
    <>
      <Stack p={2.5} direction="row" alignItems="center">
        <DeleteSlideModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onSubmit={removeSlide}
        />
        {getSlideTypeHeader(currentSlide?.slideType)}
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          flexGrow={1}
        >
          <Divider orientation="vertical" flexItem />
          <Tooltip title="Delete task">
            <IconButtonAnimate
              onClick={() => setIsDeleteModalOpen(true)}
              size="small"
              color="error"
            >
              <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>
        </Stack>
      </Stack>

      <Divider />

      <Scrollbar>
        <Stack
          spacing={3}
          sx={{ px: 2.5, py: 3, pb: 100 }}
          direction="column"
          height="100%"
        >
          <Stack direction="row">
            <Stack direction="column">
              <LabelStyle sx={{ mt: 2, fontSize: 15, typography: 'h6' }}>
                Header
              </LabelStyle>
              <OutlinedInput
                fullWidth
                multiline
                size="small"
                placeholder="Question"
                value={currentSlide?.header || ''}
                onChange={(e) => {
                  updateSlideLocal({ ...currentSlide, header: e.target.value });
                }}
                onBlur={(e) =>
                  update({ ...currentSlide, header: e.target.value })
                }
                sx={{
                  typography: 'h7',
                  // '& .MuiOutlinedInput-notchedOutline': {
                  // borderColor: 'transparent',
                  // },
                }}
              />
            </Stack>
          </Stack>

          <Stack direction="row">
            <Stack direction="column">
              <LabelStyle sx={{ mt: 2, fontSize: 15, typography: 'h6' }}>
                Sub Header
              </LabelStyle>
              <OutlinedInput
                fullWidth
                multiline
                size="small"
                placeholder="Question"
                value={currentSlide?.subHeader || ''}
                onChange={(e) => {
                  updateSlideLocal({
                    ...currentSlide,
                    subHeader: e.target.value,
                  });
                }}
                onBlur={(e) =>
                  update({ ...currentSlide, subHeader: e.target.value })
                }
                sx={{
                  typography: 'h7',
                  // '& .MuiOutlinedInput-notchedOutline': {
                  //     borderColor: 'transparent',
                  // },
                }}
              />
            </Stack>
          </Stack>

          <Stack direction="column">
            <LabelStyle sx={{ mt: 2, fontSize: 15, typography: 'h6' }}>
              Created By
            </LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              <Avatar
                key={currentSlide?.createdBy}
                src={currentSlide?.createdBy?.avatar}
                sx={{ m: 0.5, width: 36, height: 36 }}
              />
              <LabelStyle sx={{ typography: 'h7' }}>
                {currentSlide?.createdBy?.lastName}{' '}
                {currentSlide?.createdBy?.firstName}
              </LabelStyle>
            </Stack>
          </Stack>

          <Stack direction="row">
            <LabelStyle sx={{ mt: 2, fontSize: 15, typography: 'h6' }}>
              Background Cover
            </LabelStyle>

            <Stack direction="row" flexWrap="wrap">
              <SlideImageAttachment
                image={currentSlide?.image}
                setImage={(image) => {
                  updateCover(image);
                }}
              />
            </Stack>
          </Stack>

          {currentSlide?.slideType === 'QUIZ' && (
            <>
              <Divider />

              <LabelStyle sx={{ mt: 2, fontSize: 15, typography: 'h6' }}>
                Options
              </LabelStyle>

              <DragDropContext onDragEnd={onDragEnd} type="task">
                <Droppable
                  droppableId={`${currentSlide?.id}-options`}
                  type="task"
                >
                  {(provided) => (
                    <Stack
                      spacing={3}
                      sx={{}}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      height="100%"
                      direction="column"
                    >
                      {currentSlide?.options?.map((option, idx) => (
                        <Option
                          key={option.id}
                          option={option}
                          idx={idx}
                          isCorrect={currentSlide?.correctOption === option.id}
                          onChange={updateOptionLocal}
                          onBlur={handleUpdateOption}
                          onDelete={removeOption}
                          onCheck={handleCheckOption}
                        />
                      ))}

                      <Button variant="contained" onClick={createOption}>
                        Add Option
                      </Button>

                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </Stack>
      </Scrollbar>
    </>
  );
}

const Option = ({
  option,
  idx,
  isCorrect,
  onChange,
  onBlur,
  onDelete,
  onCheck,
}) => (
  <Draggable draggableId={option?.id?.toString()} index={idx}>
    {(provided) => (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <Stack key={option.id} direction="row" sx={{ p: 1 }}>
          <IconButton disabled>
            <Iconify icon={'material-symbols:arrow-right-rounded'} />
          </IconButton>
          <Checkbox
            checked={isCorrect}
            onChange={(e) =>
              onCheck({ checked: e.target.checked, option, idx })
            }
          />
          <OutlinedInput
            fullWidth
            // multiline
            size="small"
            placeholder={`Option ${idx}`}
            value={option?.title || ''}
            onChange={(e) => {
              onChange({ option: { ...option, title: e.target.value }, idx });
            }}
            onBlur={(e) =>
              onBlur({ option: { ...option, title: e.target.value }, idx })
            }
            sx={{
              typography: 'h7',
              // '& .MuiOutlinedInput-notchedOutline': {
              //     borderColor: 'transparent',
              // },
            }}
          />
          <IconButton onClick={() => onDelete(option)}>
            <Iconify icon={'system-uicons:cross'} />
          </IconButton>
        </Stack>
      </div>
    )}
  </Draggable>
);
