import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import { SkeletonKanbanColumn } from '../../components/skeleton';
import { HEADER } from '../../config';
import {
  SlideColumn,
  SlideInfoColumn,
} from '../../sections/presentation/slide';
import {
  retrievePresentation,
  setCurrentSlide,
} from '../../store/presentationSlice';
import { PATH_PAGE } from '../../routes/paths';
import Image from '../../components/Image';
import Slide from './Slide';
import SlideRealtime from './SlideRealtime';
import { retrievePresentationSession } from '../../store/presentationSessionSlice';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  // padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  // top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
  margin: 0,
}));

export default function PresentationSession() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const { id } = useParams();

  const { session } = useSelector((state) => state.presentationSession);

  const presentation = session?.presentation;
  const slides = presentation?.slides;
  const currentSlide = slides ? slides[session?.currentSlide || 0] : null;

  const fetchSession = async (id) => {
    const { payload, type, error } = await dispatch(
      retrievePresentationSession(id),
    );
    if (error) return navigate(PATH_PAGE.page404);

    return document.documentElement.requestFullscreen();
  };

  useEffect(() => {
    if (id) fetchSession(id);
  }, [id]);

  return (
    <RootStyle>
      <SlideRealtime slide={currentSlide} />
    </RootStyle>
  );
}
