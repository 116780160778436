import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { capitalCase } from 'change-case';
import jwtDecode from 'jwt-decode';
import { axiosAPI } from '../api/api';
import { PATH_USER } from '../routes/paths';
import {
  handleGetDispatch,
  handlePostDispatch,
  handlePutDispatchWithAvatar,
} from './handleDispatch';
import { setIsLoading } from './loadingSlice';
import { toastifyAction } from './toastifySlice';

const modulePrefix = 'user';

const getUserInfo = createAsyncThunk('getUserInfo', async (any, thunkAPI) => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) return {};
  const decoded = jwtDecode(accessToken);
  const url = `${modulePrefix}/${decoded.id}`;
  const data = await handleGetDispatch({ thunkAPI, url });
  return data;
});

// -------------------- Password ------------------

const sendResetPasswordEmail = createAsyncThunk(
  'sendResetPasswordEmail',
  async (postData, thunkAPI) => {
    const url = `${modulePrefix}/send-reset-password-email/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData,
      showNoti: true,
    });
    return data;
  },
);

const resetPassword = createAsyncThunk(
  'resetPassword',
  async (postData, thunkAPI) => {
    const url = `${modulePrefix}/reset-password/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData,
      showNoti: true,
    });
    return data;
  },
);

const changePassword = createAsyncThunk(
  'changePassword',
  async (postData, thunkAPI) => {
    const url = `${modulePrefix}/${postData.id}/change-password/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData,
      showNoti: true,
    });
    return data;
  },
);

const updateUser = createAsyncThunk('updateUser', async (putData, thunkAPI) => {
  const url = `${modulePrefix}/${putData.id}/`;
  const data = await handlePutDispatchWithAvatar({
    thunkAPI,
    url,
    putData,
    showNoti: true,
  });
  return data;
});

const verifyEmail = createAsyncThunk('verifyEmail', async (_, thunkAPI) => {
  const url = `${modulePrefix}/send-verify-email/`;
  const data = await handlePostDispatch({
    thunkAPI,
    url,
    postData: { redirect: PATH_USER.account },
  });
  return data;
});

const initialState = { isAdmin: false, userLst: {}, user: {} };

export const userSlice = createSlice({
  name: modulePrefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      const user = action.payload;
      state.user = user;
    });
  },
});

export const userActions = userSlice.actions;

export {
  updateUser,
  getUserInfo,
  verifyEmail,
  sendResetPasswordEmail,
  resetPassword,
  changePassword,
};
