import { useTheme } from '@emotion/react';
import { Badge, Box, IconButton, Stack, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import {
  PATH_PRESENTATION,
  PATH_PRESENTATION_SESSION,
} from '../../routes/paths';
import ChatModal from '../../sections/presentation/chat/ChatModal';
import QuestionModal from '../../sections/presentation/question/QuestionModal';
import EndSessionModal from '../../sections/presentation/session/EndSessionModal';
import OptionVoteBarChart from '../../sections/presentation/slide/BarChart';
import { WebSocketContext } from '../../SocketContext';
import {
  endPresentationSession,
  fetchChat,
  fetchQuestions,
  setIsChatOpen,
  setIsQuestionOpen,
  setUnreadMsgCount,
  setUnreadQuestionCount,
  updatePresentationSession,
} from '../../store/presentationSessionSlice';

export default function SlideRealtime({ slide }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    session,
    unreadMsgCount,
    isChatOpen,
    chatNextPage,
    unreadQuestionCount,
    isQuestionOpen,
    questionNextPage,
  } = useSelector((state) => state.presentationSession);

  const presentation = session?.presentation;
  const [isEndModalOpen, setIsEndModalOpen] = useState(false);

  const { socket } = useContext(WebSocketContext);

  useEffect(() => {
    if (!session.id) return;
    dispatch(fetchChat({ id: session.id, page: chatNextPage }));
    dispatch(fetchQuestions({ id: session.id, page: questionNextPage }));
  }, [session.id]);

  useEffect(() => {
    if (isChatOpen) dispatch(setUnreadMsgCount(0));
  }, [isChatOpen]);

  useEffect(() => {
    if (isQuestionOpen) dispatch(setUnreadQuestionCount(0));
  }, [isQuestionOpen]);

  useEffect(() => {
    if (!socket || !session.id) return;
    socket.emit('join_presentation_session', { id: session.id });
  }, [socket, session]);

  if (!slide) return <></>;

  const goNextSlide = async () => {
    if (slide._order === presentation?.slides?.length - 1) return;
    await dispatch(
      updatePresentationSession({
        id: session.id,
        session: {
          ...session,
          currentSlide: session.currentSlide + 1,
        },
      }),
    );
  };

  const goBackSlide = async () => {
    if (slide._order === 0) return;
    await dispatch(
      updatePresentationSession({
        id: session.id,
        session: {
          ...session,
          currentSlide: session.currentSlide - 1,
        },
      }),
    );
  };

  const copyLink = () => {
    copy(window.location.host + PATH_PRESENTATION_SESSION.visitor(session.id));
  };

  const setChatModalOpen = (val) => {
    dispatch(setIsChatOpen(val));
  };

  const setQuestionModalOpen = (val) => {
    dispatch(setIsQuestionOpen(val));
  };

  const end = async () => {
    await dispatch(endPresentationSession({ id: session.id }));
    setIsEndModalOpen(false);
    navigate(PATH_PRESENTATION.root);
  };
  if (!slide) return <></>;
  return (
    <Box sx={{ position: 'relative', p: 0, bgColor: theme.palette.grey[100] }}>
      {slide?.slideType === 'QUIZ' ? (
        <QuizSlide
          slide={slide}
          goNextSlide={goNextSlide}
          goBackSlide={goBackSlide}
        />
      ) : (
        <HeadingSlide slide={slide} />
      )}

      <Box
        sx={{
          position: 'absolute',
          textAlign: 'center',
          p: 10,
          width: '80%',
          height: '80%',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%)`,
        }}
      >
        {slide?.slideType === 'QUIZ' && <OptionVoteBarChart slide={slide} />}
      </Box>
      <ChatModal isOpen={isChatOpen} onClose={() => setChatModalOpen(false)} />
      <QuestionModal
        isOpen={isQuestionOpen}
        onClose={() => setQuestionModalOpen(false)}
      />
      <EndSessionModal
        isOpen={isEndModalOpen}
        onClose={() => setIsEndModalOpen(false)}
        onSubmit={end}
      />

      <Stack
        direction="row"
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: '10%',
          my: 5,
          alignItems: 'center',
        }}
      >
        <IconButton
          variant="contained"
          color="warning"
          onClick={() => setChatModalOpen(true)}
        >
          <Badge badgeContent={unreadMsgCount} color="error">
            {' '}
            <Iconify icon={'material-symbols:chat'} />
          </Badge>
        </IconButton>
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => setQuestionModalOpen(true)}
        >
          <Badge badgeContent={unreadQuestionCount} color="error">
            {' '}
            <Iconify icon={'mdi:chat-question'} />
          </Badge>
        </IconButton>
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => copyLink()}
        >
          <Iconify icon={'mdi:link-box-variant-outline'} />
        </IconButton>
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => goBackSlide()}
        >
          <Iconify icon={'material-symbols:arrow-circle-left-rounded'} />
        </IconButton>
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => goNextSlide()}
        >
          <Iconify icon={'material-symbols:arrow-circle-right'} />
        </IconButton>
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => document.documentElement.requestFullscreen()}
        >
          <Iconify icon={'material-symbols:fullscreen'} />
        </IconButton>
        <IconButton
          variant="contained"
          color="error"
          onClick={() => setIsEndModalOpen(true)}
        >
          <Iconify icon={'ion:exit'} />
        </IconButton>
      </Stack>
      <Image
        src={
          slide?.image?.preview ||
          slide?.image ||
          '/assets/slide_placeholder.jpg'
        }
        ratio="16/9"
        sx={{ zIndex: -100 }}
      />
    </Box>
  );
}

const QuizSlide = ({ slide, goBackSlide, goNextSlide }) => (
  <>
    <Box sx={{ position: 'absolute', p: 2 }}>
      <Typography variant="h3">{slide?.header}</Typography>
      <Typography variant="h6">{slide?.subHeader}</Typography>
    </Box>
  </>
);

const HeadingSlide = ({ slide }) => (
  <>
    <Box
      sx={{
        position: 'absolute',
        textAlign: 'center',
        p: 10,
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
      }}
    >
      <Typography variant="h3">{slide?.header}</Typography>
      <Typography variant="h6">{slide?.subHeader}</Typography>
    </Box>
  </>
);
