import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  Button,
} from '@mui/material';
import { fData } from '../../utils/formatNumber';
import {
  PATH_DASHBOARD,
  PATH_GROUP,
  PATH_PRESENTATION,
} from '../../routes/paths';
import Label from '../../components/Label';
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFUploadCover,
} from '../../components/hook-form';
import {
  createPresentation,
  updatePresentation,
} from '../../store/presentationSlice';
import { appDispatch } from '../../store/appDispatch';
import { getUserInfo } from '../../store/userSlice';

// ----------------------------------------------------------------------

PresentationForm.propTypes = {
  isEdit: PropTypes.bool,
  currentPresentation: PropTypes.object,
};

export default function PresentationForm({ isEdit, currentPresentation }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const Schema = Yup.object().shape({
    name: Yup.string()
      .required('Presentation Name is required')
      .min(6, 'Name must be at least 6 characters'),
    description: Yup.string().required('Description is a required field'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentPresentation?.name || '',
      description: currentPresentation?.description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPresentation, user],
  );

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentPresentation) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentPresentation]);

  const onSubmit = async (data) => {
    if (isEdit) {
      await dispatch(
        updatePresentation({ ...data, id: currentPresentation.id }),
      );
    } else {
      const { type } = await dispatch(createPresentation(data));
      if (type !== 'error') navigate(PATH_PRESENTATION.root);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField name="name" label="Name" />
              <RHFTextField
                name="description"
                multiline
                rows={4}
                label="Description"
              />
              <Stack direction="row">
                {currentPresentation?.id && (
                  <Button
                    sx={{ mr: 1 }}
                    onClick={() =>
                      navigate(PATH_PRESENTATION.slide(currentPresentation?.id))
                    }
                  >
                    Go
                  </Button>
                )}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {!isEdit ? 'Create  Presentation' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
