import { IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import Iconify from '../../../components/Iconify';
//
import Scrollbar from '../../../components/Scrollbar';
// import LightboxModal from '../../../components/LightboxModal';
import ChatMessageItem from './ChatMessageItem';

// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default function ChatMessageList({ messages, onLoadMore }) {
  const scrollRef = useRef(null);
  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [messages]);

  return (
    <>
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>
        <Stack sx={{ alignItems: 'center' }}>
          <IconButton onClick={onLoadMore}>
            <Iconify icon={'material-symbols:keyboard-double-arrow-up'} />
          </IconButton>
        </Stack>
        {messages?.map((message) => (
          <ChatMessageItem key={message.id} message={message} />
        ))}
      </Scrollbar>
    </>
  );
}
