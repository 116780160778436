// hooks
// utils
import { useSelector } from 'react-redux';
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useSelector((state) => state.user);

  return (
    <Avatar
      src={user?.avatar}
      alt={user?.firstName}
      color={user?.avatar ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
