import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
import { useState, useCallback } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
// components
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
// import LightboxModal from '../../../components/LightboxModal';
import { varFade } from '../../../components/animate';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

SlideImageAttachment.propTypes = {
  image: PropTypes.any,
  setImage: PropTypes.func,
};

export default function SlideImageAttachment({ image, setImage }) {
  const handleRemove = () => {
    setImage(null);
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setImage(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }),
      );
    },
    [setImage],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  return (
    <>
      {image && (
        <Box
          key={isString(image) ? image : image.name}
          {...varFade().inRight}
          sx={{
            p: 0,
            m: 0.5,
            width: 64,
            height: 64,
            borderRadius: 1,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Image
            src={isString(image) ? image : image.preview}
            sx={{
              height: 1,
              position: 'absolute',
              border: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          />
          <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
            <IconButton
              size="small"
              onClick={() => handleRemove(image)}
              sx={{
                p: '2px',
                color: 'common.white',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                },
              }}
            >
              <Iconify icon={'eva:close-fill'} />
            </IconButton>
          </Box>
        </Box>
      )}

      {!image && (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          <input {...getInputProps()} />

          <Iconify icon={'eva:plus-fill'} sx={{ color: 'text.secondary' }} />
        </DropZoneStyle>
      )}
    </>
  );
}
