import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
//
// import UserPage from '../pages/UserPage';
import LoginPage from '../pages/Auth/LoginPage';
import PresentationList from '../pages/Presentation/PresentationList';
// import SlideDetailPage from '../pages/SlideDetailPage';
import RegisterPage from '../pages/Auth/RegisterPage';
import ResetPassword from '../pages/Auth/ResetPassword';
import UserAccount from '../pages/User/UserAccount';

import PresentationLayout from '../layouts/presentation';
import NewPassword from '../pages/Auth/NewPassword';
import GroupCreateOrEdit from '../pages/Group/GroupCreateOrEdit';
import GroupFeed from '../pages/Group/GroupFeed';
import { GroupJoin } from '../pages/Group/GroupJoin';
import GroupList from '../pages/Group/GroupList';
import Presentation from '../pages/Presentation/Presentation';
import PresentationCreateOrEdit from '../pages/Presentation/PresentationCreateOrEdit';
import PresentationSession from '../pages/Presentation/PresentationSession';
import PresentationSessionVisitor from '../pages/Presentation/PresentationSessionVisitor';
import Page404 from '../pages/Page404';
import * as PATH from './paths';
import PresentationSessionList from '../pages/Presentation/PresentationSessionList';
import PresentationSessionVoteResultList from '../pages/Presentation/PresentationSessionVoteResultList';

// ----------------------------------------------------------------------
export const AuthGuard = ({ children }) => {
  const { isAuth } = useSelector((state) => state.auth);
  if (!isAuth) {
    return <Navigate to={PATH.PATH_AUTH.login} replace />;
  }

  return children;
};

export const GuestGuard = ({ children }) => {
  const { isAuth } = useSelector((state) => state.auth);

  if (isAuth) {
    return <Navigate to={PATH.PATH_PRESENTATION.root} replace />;
  }

  return children;
};

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/presentation" />,
        },
        {
          path: 'user',
          children: [
            {
              path: 'account',
              element: <UserAccount />,
            },
          ],
        },
        {
          path: 'group',
          children: [
            {
              path: '',
              element: <GroupList />,
            },
            {
              path: ':id',
              element: <GroupFeed />,
            },
            {
              path: 'new',
              element: <GroupCreateOrEdit />,
            },
            {
              path: ':id/edit',
              element: <GroupCreateOrEdit />,
            },
            {
              path: ':id/join',
              element: <GroupJoin />,
            },
          ],
        },
        {
          element: <Navigate to="/presentation" />,
          index: true,
        },
        {
          path: 'presentation',
          children: [
            {
              path: '',
              element: <PresentationList />,
            },

            {
              path: ':id/edit',
              element: <PresentationCreateOrEdit />,
            },
            {
              path: 'new',
              element: <PresentationCreateOrEdit />,
            },
          ],
        },
        {
          path: 'presentation-histories',
          children: [
            {
              path: ':id',
              element: <PresentationSessionList />,
            },
            {
              path: ':id/results',
              element: <PresentationSessionVoteResultList />,
            },
          ],
        },

        // {
        //   path: 'slide/:id',
        //   element: (
        //     <AuthGuard>
        //       <SlideDetailPage />
        //     </AuthGuard>
        //   ),
        // },
      ],
    },
    {
      path: 'presentation',
      element: (
        <AuthGuard>
          <PresentationLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ':id',
          element: <Presentation />,
        },
      ],
    },
    {
      path: 'presentation-session',
      children: [
        {
          path: ':id',
          element: <PresentationSession />,
        },
        {
          path: ':id/result',
          element: <PresentationSession />,
        },
        {
          path: 'visitor/:id',
          element: <PresentationSessionVisitor />,
        },
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
        {
          path: 'new-password',
          element: <NewPassword />,
        },
      ],
    },
    { path: '404', element: <Page404 /> },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
