import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Avatar, Divider, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// utils
import cssStyles from '../../../utils/cssStyles';
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Image from '../../../components/Image';
// import SocialsButton from '../../../../components/SocialsButton';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_GROUP } from '../../../routes/paths';

// ----------------------------------------------------------------------

// const OverlayStyle = styled('div')(({ theme }) => ({
//   ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
//   top: 0,
//   zIndex: 8,
//   content: "''",
//   width: '100%',
//   height: '100%',
//   position: 'absolute',
// }));

// ----------------------------------------------------------------------

GroupCard.propTypes = {
  group: PropTypes.object.isRequired,
};

export default function GroupCard({ group }) {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(PATH_GROUP.feed(group.id));
  };

  return (
    <Card sx={{ textAlign: 'center' }} onClick={onClick}>
      <Box sx={{ position: 'relative' }}>
        <SvgIconStyle
          src="https://minimal-assets-api.vercel.app/assets/icons/shape-avatar.svg"
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            left: 0,
            right: 0,
            bottom: -26,
            mx: 'auto',
            position: 'absolute',
            color: 'background.paper',
          }}
        />
        <Avatar
          alt={group?.name}
          src={group?.avatar}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            left: 0,
            right: 0,
            bottom: -32,
            mx: 'auto',
            position: 'absolute',
          }}
        />
        {/* <OverlayStyle /> */}
        <Image
          src={group?.cover || '/assets/bg_card.png'}
          alt={group?.cover}
          ratio="16/9"
        />
      </Box>

      <Typography variant="subtitle1" sx={{ mt: 6 }}>
        {group?.name}
      </Typography>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {group?.description}
      </Typography>

      <Stack alignItems="center">
        {/* <SocialsButton initialColor sx={{ my: 2.5 }} /> */}
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Box
        sx={{ py: 3, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}
      >
        <div>
          <Typography
            variant="caption"
            component="div"
            sx={{ mb: 0.75, color: 'text.disabled' }}
          >
            Member
          </Typography>
          <Typography variant="subtitle1">
            {fShortenNumber(group?.memberCount)}
          </Typography>
        </div>

        <div>
          <Typography
            variant="caption"
            component="div"
            sx={{ mb: 0.75, color: 'text.disabled' }}
          >
            Presentation
          </Typography>
          <Typography variant="subtitle1">
            {fShortenNumber(group?.presentations?.length)}
          </Typography>
        </div>

        <div>
          <Typography
            variant="caption"
            component="div"
            sx={{ mb: 0.75, color: 'text.disabled' }}
          >
            Total Post
          </Typography>
          <Typography variant="subtitle1">
            {fShortenNumber(group?.posts?.length)}
          </Typography>
        </div>
      </Box>
    </Card>
  );
}
