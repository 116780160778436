import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_ENDPOINT } from './apiEndpoint';
import {
  handleGetDispatch,
  handlePostDispatch,
  handlePutDispatch,
} from './handleDispatch';

const ENDPOINT = API_ENDPOINT.PRESENTATION_SESSION;

const startSession = createAsyncThunk(
  'startSession',
  async ({ id, group }, thunkAPI) => {
    const url = `${API_ENDPOINT.PRESENTATION}/${id}/start/`;
    const postData = group ? { group } : {};
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData,
      showNoti: false,
    });
    return data;
  },
);

const retrievePresentationSession = createAsyncThunk(
  'retrievePresentationSession',
  async (id, thunkAPI) => {
    const url = `${ENDPOINT}/${id}`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
    });
    return data;
  },
);

const fetchPresentationSessionVotes = createAsyncThunk(
  'fetchPresentationSessionVotes',
  async (id, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/vote/`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
    });
    return data.results;
  },
);

const updatePresentationSession = createAsyncThunk(
  'updatePresentationSession',
  async ({ id, session }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/`;
    const data = await handlePutDispatch({
      thunkAPI,
      url,
      putData: session,
    });
    return data;
  },
);

const endPresentationSession = createAsyncThunk(
  'endPresentationSession',
  async ({ id, session }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/end/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: {},
    });
    return data;
  },
);

const fetchChat = createAsyncThunk(
  'fetchChat',
  async ({ id, page }, thunkAPI) => {
    if (!page) return { results: [] };
    const url = `${ENDPOINT}/${id}/chat?page=${page}`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
    });
    return data;
  },
);

const sendMessage = createAsyncThunk(
  'sendMessage',
  async ({ id, message }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/chat/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: message,
    });
    return data;
  },
);

const sendVote = createAsyncThunk(
  'sendVote',
  async ({ id, option }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/vote/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: option,
    });
    return data;
  },
);

const sendQuestion = createAsyncThunk(
  'sendQuestion',
  async ({ id, question }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/question/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: question,
    });
    return data;
  },
);

const fetchQuestions = createAsyncThunk(
  'fetchQuestions',
  async ({ id, page }, thunkAPI) => {
    if (!page) return { results: [] };
    const url = `${ENDPOINT}/${id}/question?page=${page}`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
    });
    return data;
  },
);

const markQuestionAsAnswered = createAsyncThunk(
  'markQuestionAsAnswered',
  async ({ id, question }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/question/${question.id}/`;
    const data = await handlePutDispatch({
      thunkAPI,
      url,
      putData: { isAnswered: true },
    });
    return data;
  },
);

const upvoteQuestion = createAsyncThunk(
  'upvoteQuestion',
  async ({ id, question }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/question/${question.id}/vote/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      putData: {},
    });
    return data;
  },
);

const initialState = {
  session: {},
  chat: [],
  unreadMsgCount: 0,
  isChatOpen: false,
  chatNextPage: 1,

  questions: [],
  unreadQuestionCount: 0,
  isQuestionOpen: false,
  questionNextPage: 1,
};

export const presentationSessionSlice = createSlice({
  name: API_ENDPOINT.PRESENTATION_SESSION,
  initialState,
  reducers: {
    setSession: (state, action) => ({ ...state, session: action.payload }),

    // ---------- CHAT -----------
    pushMessage: (state, action) => ({
      ...state,
      chat: [...state.chat, action.payload],
      unreadMsgCount: state.unreadMsgCount + +(state.isChatOpen === false),
    }),
    setUnreadMsgCount: (state, action) => ({
      ...state,
      unreadMsgCount: action.payload,
    }),
    setIsChatOpen: (state, action) => ({
      ...state,
      isChatOpen: action.payload,
    }),

    // ------ QUESTION -----------
    pushQuestion: (state, action) => ({
      ...state,
      questions: [...state.questions, action.payload],
      unreadQuestionCount:
        state.unreadQuestionCount + +(state.isQuestionOpen === false),
    }),
    updateQuestion: (state, action) => {
      const question = action.payload;
      const { questions } = state;
      for (let i = 0; i < questions.length; i += 1) {
        if (questions[i].id === question.id) {
          questions[i] = question;
          break;
        }
      }
      return state;
    },
    setUnreadQuestionCount: (state, action) => ({
      ...state,
      unreadQuestionCount: action.payload,
    }),
    setIsQuestionOpen: (state, action) => ({
      ...state,
      isQuestionOpen: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(retrievePresentationSession.fulfilled, (state, action) => {
      state.session = action.payload;
    });
    builder.addCase(updatePresentationSession.fulfilled, (state, action) => {
      state.session = action.payload;
    });
    builder.addCase(startSession.fulfilled, (state, action) => {
      state.session = action.payload;
    });
    builder.addCase(fetchChat.fulfilled, (state, action) => {
      state.chat = [...action.payload.results?.reverse(), ...state.chat];
      state.chatNextPage = action.payload?.next
        ? new URLSearchParams(action.payload.next.split('?')[1]).get('page')
        : null;
    });
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      state.questions = [
        ...action.payload.results?.reverse(),
        ...state.questions,
      ];
      state.questionNextPage = action.payload?.next
        ? new URLSearchParams(action.payload.next.split('?')[1]).get('page')
        : null;
    });
  },
});

export const {
  setSession,
  pushMessage,
  setUnreadMsgCount,
  setIsChatOpen,

  pushQuestion,
  updateQuestion,
  setUnreadQuestionCount,
  setIsQuestionOpen,
} = presentationSessionSlice.actions;

export {
  startSession,
  retrievePresentationSession,
  fetchPresentationSessionVotes,
  updatePresentationSession,
  endPresentationSession,
  fetchChat,
  sendMessage,
  sendVote,
  fetchQuestions,
  sendQuestion,
  markQuestionAsAnswered,
  upvoteQuestion,
};
