import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_ENDPOINT } from './apiEndpoint';
import {
  handleDeleteDispatch,
  handleGetDispatch,
  handlePostDispatch,
  handlePutDispatch,
  handlePutDispatchWithAvatar,
} from './handleDispatch';

const ENDPOINT = API_ENDPOINT.PRESENTATION;

const getMyPresentations = createAsyncThunk(
  'getMyPresentations',
  async (_, thunkAPI) => {
    const url = `${ENDPOINT}`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
    });
    return data.results;
  },
);

const getMyCollabPresentations = createAsyncThunk(
  'getMyCollabPresentations',
  async (_, thunkAPI) => {
    const url = `${ENDPOINT}/collabList`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
    });
    return data.results;
  },
);

const getPresentationSessions = createAsyncThunk(
  'getPresentationSessions',
  async (id, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/sessionList/`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
    });
    return data.results;
  },
);

const retrievePresentation = createAsyncThunk(
  'retrievePresentation',
  async (id, thunkAPI) => {
    const url = `${ENDPOINT}/${id}`;
    const data = await handleGetDispatch({
      thunkAPI,
      url,
      showNoti: false,
    });
    return data;
  },
);

const createPresentation = createAsyncThunk(
  'createPresentation',
  async (postData, thunkAPI) => {
    const url = `${ENDPOINT}/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData,
      showNoti: true,
    });
    return data;
  },
);

const updatePresentation = createAsyncThunk(
  'updatePresentation',
  async (putData, thunkAPI) => {
    delete putData.owner;
    const url = `${ENDPOINT}/${putData.id}/`;
    const data = await handlePutDispatch({
      thunkAPI,
      url,
      putData,
      showNoti: true,
    });
    return data;
  },
);

const deletePresentation = createAsyncThunk(
  'deletePresentation',
  async (id, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/`;
    const data = await handleDeleteDispatch({
      thunkAPI,
      url,
      showNoti: true,
    });
    thunkAPI.dispatch(getMyPresentations({}));
    return data;
  },
);

const addCollaborator = createAsyncThunk(
  'addCollaborator',
  async ({ id, email }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/addCollaborator/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: { email },
      showNoti: true,
    });
    return data;
  },
);

const removeCollaborator = createAsyncThunk(
  'removeCollaborator',
  async ({ id, userId }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/removeCollaborator/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: { id: userId },
      showNoti: true,
    });
    return data;
  },
);

const addSlide = createAsyncThunk(
  'addSlide',
  async ({ id, slideType }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/slide/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: { slideType },
      showNoti: false,
    });
    return data;
  },
);

const updateSlide = createAsyncThunk(
  'updateSlide',
  async ({ id, slide }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/slide/${slide.id}/`;
    console.log(slide);
    const data = await handlePutDispatchWithAvatar({
      thunkAPI,
      url,
      putData: slide,
      showNoti: false,
    });
    console.log(data);
    return data;
  },
);

const deleteSlideAvatar = createAsyncThunk(
  'deleteSlideAvatar',
  async ({ id, slide }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/slide/${slide.id}/image/`;
    const data = await handleDeleteDispatch({
      thunkAPI,
      url,
      showNoti: false,
    });
    return data;
  },
);

const deleteSlide = createAsyncThunk(
  'deleteSlide',
  async ({ id, slide }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/slide/${slide.id}/`;
    const data = await handleDeleteDispatch({
      thunkAPI,
      url,
      showNoti: false,
    });
    return data;
  },
);

const setSlidesOrder = createAsyncThunk(
  'setSlidesOrder',
  async ({ id, slides }, thunkAPI) => {
    const url = `${ENDPOINT}/${id}/slideOrder/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: { ids: slides?.map(({ id }) => id) },
      showNoti: false,
    });
    return data;
  },
);

// ------------------- OPTION ---------------------------

const SLIDE_ENDPOINT = API_ENDPOINT.SLIDE;

const addOption = createAsyncThunk(
  'addOption',
  async ({ id, option = {} }, thunkAPI) => {
    const url = `${SLIDE_ENDPOINT}/${id}/option/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: option,
      showNoti: false,
    });
    return data;
  },
);

const deleteOption = createAsyncThunk(
  'deleteOptioin',
  async ({ id, option }, thunkAPI) => {
    const url = `${SLIDE_ENDPOINT}/${id}/option/${option.id}/`;
    const data = await handleDeleteDispatch({
      thunkAPI,
      url,
      showNoti: false,
    });
    return data;
  },
);

const updateOption = createAsyncThunk(
  'updateOption',
  async ({ id, option }, thunkAPI) => {
    const url = `${SLIDE_ENDPOINT}/${id}/option/${option.id}/`;
    const data = await handlePutDispatch({
      thunkAPI,
      url,
      putData: option,
      showNoti: false,
    });
    return data;
  },
);

const setOptionsOrder = createAsyncThunk(
  'setOptionsOrder',
  async ({ id, options }, thunkAPI) => {
    const url = `${SLIDE_ENDPOINT}/${id}/optionOrder/`;
    const data = await handlePostDispatch({
      thunkAPI,
      url,
      postData: { ids: options?.map(({ id }) => id) },
      showNoti: false,
    });
    return data;
  },
);

const initialState = {
  myPresentations: [],
  myCollabPresentations: [],
  currentPresentation: null,
  currentSlide: null,
  isSaving: false,
};

const updateStateByOption = (state, action) => {
  const { currentPresentation, currentSlide } = state;
  const { slides } = currentPresentation;
  for (let i = 0; i < slides.length; i += 1) {
    if (slides[i].id === currentSlide.id) {
      slides[i] = action.payload;
      break;
    }
  }
  state.currentPresentation = { ...currentPresentation, slides };
  state.currentSlide = action.payload;
  return state;
};

export const presentationSlice = createSlice({
  name: ENDPOINT,
  initialState,
  reducers: {
    setIsSaving: (state, action) => ({ ...state, isSaving: action.payload }),
    setCurrentSlide: (state, action) => ({
      ...state,
      currentSlide: action.payload,
    }),
    setCurrentPresentation: (state, action) => ({
      ...state,
      currentPresentation: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getMyPresentations.fulfilled, (state, action) => {
      state.myPresentations = action.payload;
    });
    builder.addCase(getMyCollabPresentations.fulfilled, (state, action) => {
      state.myCollabPresentations = action.payload;
    });
    builder.addCase(retrievePresentation.fulfilled, (state, action) => {
      state.currentPresentation = action.payload;
    });
    builder.addCase(addSlide.fulfilled, (state, action) => {
      state.currentPresentation = action.payload;
    });
    builder.addCase(updateSlide.fulfilled, (state, action) => {
      state.currentPresentation = action.payload;
    });
    builder.addCase(deleteSlideAvatar.fulfilled, (state, action) => {
      state.currentPresentation = action.payload;
    });
    builder.addCase(deleteSlide.fulfilled, (state, action) => {
      state.currentPresentation = action.payload;
    });
    builder.addCase(setSlidesOrder.fulfilled, (state, action) => {
      state.currentPresentation = action.payload;
    });

    // ----------- Option ----------
    builder.addCase(addOption.fulfilled, (state, action) =>
      updateStateByOption(state, action),
    );

    builder.addCase(updateOption.fulfilled, (state, action) =>
      updateStateByOption(state, action),
    );

    builder.addCase(deleteOption.fulfilled, (state, action) =>
      updateStateByOption(state, action),
    );

    builder.addCase(setOptionsOrder.fulfilled, (state, action) =>
      updateStateByOption(state, action),
    );
  },
});

export const { setIsSaving, setCurrentPresentation, setCurrentSlide } =
  presentationSlice.actions;

export {
  getMyPresentations,
  getMyCollabPresentations,
  getPresentationSessions,
  retrievePresentation,
  createPresentation,
  updatePresentation,
  deletePresentation,
  addCollaborator,
  removeCollaborator,
  addSlide,
  updateSlide,
  deleteSlideAvatar,
  setSlidesOrder,
  deleteSlide,
  addOption,
  updateOption,
  setOptionsOrder,
  deleteOption,
};
