import { Box, Container, Typography } from '@mui/material';
import PermissionDeniedIllustration from '../assets/illustration_permission_denied';

export default function PermissionDeniedContainer() {
  return (
    <Container>
      <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
        <Typography variant="h3" paragraph>
          Permission Denied
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          You don't have Permission to access this section
        </Typography>
        <PermissionDeniedIllustration
          sx={{ height: 260, my: { xs: 5, sm: 10 } }}
        />
      </Box>
    </Container>
  );
}
