import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  Button,
} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import { fDate } from '../../../utils/formatTime';
// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

GroupProfileAbout.propTypes = {
  group: PropTypes.object,
};

export default function GroupProfileAbout({ group }) {
  return (
    <Card>
      <CardHeader title="About" />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="body2">{group?.description}</Typography>

        <Stack direction="row">
          <IconStyle icon={'eva:pin-fill'} />
          <Typography variant="body2">
            Created At &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {fDate(group?.createdAt)}
            </Link>
          </Typography>
        </Stack>

        {/* <Stack direction="row">
          <IconStyle icon={'eva:email-fill'} />
          <Typography variant="body2">{email}</Typography>
        </Stack> */}

        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            Owner &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {group?.owner.email}
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            Co-Owner &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {group?.coOwner?.email}
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
